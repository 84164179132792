import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.scss';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import store from './store/index'
import * as Sentry from '@sentry/browser'

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

Sentry.init({ dsn: "https://529dc00f8964408c8830309e3fb158d2@sentry.io/2952212" });

render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
)

serviceWorker.unregister();