import React, {Component} from 'react';
import DesktopHeader from '../../containers/homePage/DesktopHeader';
import MessageList from './messageList/MessageList';
import MessageBlock from './messageBlock/MessageBlock';
import MobileMenu from '../../containers/homePage/MobileMenu';

class Messages extends Component {
    state={
        viewChat: false,
    }
    toggleViewChat = () => {
        this.setState({
            viewChat: !this.state.viewChat,
        })
    }

    componentDidMount(){
        const { msgFromIdentity } = this.props;
        this.props.clrStates();
        this.props.getUserSubscribedChannels(msgFromIdentity);
    }
    static getDerivedStateFromProps(props, state){
        const { subscribedChannels, messages, secondUserData } = props;
        if(subscribedChannels[0] && messages.length===0 && secondUserData===null){
            const channelDetail = props.subscribedChannels[0];
            props.setActiveUserChatIndex(0);
            props.setCurrentChannelAndMessages(channelDetail);
        }
        return {}
    }
    render() {
        const { subscribedChannels, messages, secondUserData, twilioToken } = this.props;
        return (
            <React.Fragment>
                <DesktopHeader/>
                {
                subscribedChannels && subscribedChannels.length > 0 ?
                <div className="custom-container-wrapper message-wrapper screen-height chat-histroy-wrapper">
                    {/* Required later
                    <div className="pagination-container">
                        <span className="custom-pagination">Showing 10 of 300</span>
                    </div> */}
                    <div className={`messages-members-container ${this.state.viewChat?'message-member':''}`}>
                        <span className="mobile-message-header">Messages</span>
                        <MessageList
                            subscribedChannels={subscribedChannels}
                            setCurrentChannelAndMessages={this.props.setCurrentChannelAndMessages}
                            setActiveUserChatIndex={this.props.setActiveUserChatIndex}
                            activeUserChatIndex={this.props.activeUserChatIndex}
                            twilioToken={twilioToken}
                            viewChat={this.state.viewChat}
                            toggleViewChat={this.toggleViewChat}
                        />
                        <MessageBlock
                            messages={messages}
                            secondUserData={secondUserData}
                            viewChat={this.state.viewChat}
                            toggleViewChat={this.toggleViewChat}
                        />
                    </div>
                </div>
                : <div className="loading-btn"> 
                    <div className="pagination-container pagination-container-admin-messages">
                        <span className="custom-pagination">No Messages</span>
                    </div>
                </div>
                }
                <MobileMenu />
            </React.Fragment>
        );
    }
}

export default Messages;