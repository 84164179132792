import React, { Component } from "react";
import logo from "../../commons/images/logo.svg";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

class AboutUsModal extends Component {
    state = {};
    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="aboutUs-modal">
                <ModalHeader toggle={this.props.toggle} className="modal-head">
                    <img src={logo} alt="Gradshack" />
                </ModalHeader>
                <ModalBody className="about-details">
                    <div className="faq-wrapper screen-height">
                        <div className="custom-profile-container faq-container">
                            <div className="faq-content">
                                <p className="faq-desc">
                                    GradShack provides actionable interpersonal connections for people excited about new
                                    ventures. School communities (both graduate and undergraduate programs) are the hubs
                                    around which our introductions are suggested, though users are free to connect
                                    across schools as well. GradShack is open to both alumni and students, and
                                    “intergenerational collaboration” is strongly encouraged.
                                </p>
                            </div>

                            <div className="faq-content">
                                <p className="faq-desc">
                                    Use cases for joining GradShack are various and open-ended. A few examples:
                                </p>
                                <p className="faq-desc">
                                    - You have a great business idea but no time to pursue it. You use GradShack to find
                                    a talented, aspiring entrepreneur who hasn’t landed on their own idea yet and is
                                    excited about moving yours forward.
                                </p>
                                <p className="faq-desc">
                                    - You want to start a company but haven’t found that perfect idea and/or cofounder
                                    yet. You use GradShack to find a partner.
                                </p>
                                <p className="faq-desc">
                                    - You’re running an early-stage venture and use GradShack to find a co-founder,
                                    early hire, advisor, or beta tester.
                                </p>
                                <p className="faq-desc">
                                    - You’re exploring start-up ideas or building a company in a certain sector and use
                                    GradShack to find people with a stated interest in supporting innovation in that
                                    sector.
                                </p>
                                <p className="faq-desc">
                                    - You are an advisor and use GradShack to learn about exciting projects
                                    that need your help.
                                </p>
                                <p className="faq-desc">
                                    - You’d like to have a super-basic MVP to help test your business concept and/or
                                    recruit a team, but you don’t have the technical chops to build it yourself, and
                                    you’re not ready to give away half the company. You use GradShack to find a reliable
                                    technical partner who can quickly and inexpensively build you an “MVP-lite.”
                                </p>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default AboutUsModal;
