import React, { Component } from "react";
import DesktopHeader from '../../containers/homePage/DesktopHeader';
import Filters from '../../containers/Filters';
import FilterUsers from '../../containers/FilterUsers';
import MobileMenu from '../../containers/homePage/MobileMenu';
import Spinner from '../spinner/Spinner'

class HomePage extends Component {

    componentDidMount(){
        this.props.getUserData();
    }

    render() {
        const { tcClient, newMessageFromCount } = this.props;

        return (
            <React.Fragment>
                { tcClient ? <DesktopHeader tcClient={tcClient} newMessageFromCount={newMessageFromCount}/> : null}
				<Spinner/>
                <div className="custom-container-wrapper users-section">
                    <Filters/>
                    { tcClient ? <FilterUsers /> : null}
                </div>
                { tcClient ? <MobileMenu tcClient={tcClient} newMessageFromCount={newMessageFromCount}/> : null }
            </React.Fragment>
        );
    }
}

export default HomePage;