import { Client } from 'twilio-chat';
import store from '../store';
import { createNamespacer } from '../utils/reducers';
import { TWILIOCHAT } from '../constants/namespacer';
import { getMessageCount } from '../constants/fixedValues'

const twilioChatNamespacer = createNamespacer(TWILIOCHAT);

class ChatClient {
    constructor(){
        this.client = null;
    }

    async initialize(token) {
        if(!this.client){
            try{
                this.client = await Client.create(token);
                return this.client;
            }
            catch(error){
                console.log(error)
            }
        }
        return this.client; 
    }

    attachHandlersToChannel = (channel) => {
        this.client.on('messageAdded', async (msg) => {
            const newMessageOnChannel = msg.channel;
            try{
                const messages = await newMessageOnChannel.getMessages(getMessageCount);
                store.dispatch({
                    type: 'TWILIOCHAT:SET_MESSAGES',
                    messages: messages.items
                })

                const subscribedChannels = store.getState().twilioChat.subscribedChannels;
                    
                let CurrentChannelIndexArr;
                subscribedChannels.forEach((channelDetail, index) => {
                    if(channelDetail.channel.sid === newMessageOnChannel.sid){
                        CurrentChannelIndexArr = index
                        return CurrentChannelIndexArr;
                    }
                });
    
                store.dispatch({
                    type: twilioChatNamespacer('SET_SUBSCRIBED_CHANNEL'),
                    subscribedChannels: [
                        ...subscribedChannels.slice(0,CurrentChannelIndexArr),
                        {
                            ...subscribedChannels[CurrentChannelIndexArr],
                            messages: messages,
                            latestMessage: messages.items.length>0 ? messages.items[messages.items.length-1].body : '',
                            latestMessageTimeStamp: new Date(),
                        },
                        ...subscribedChannels.slice(CurrentChannelIndexArr+1)
                    ],
                })

                store.dispatch({
                    type: twilioChatNamespacer('SET_SECOND_USER_DATA'),
                    secondUserData: subscribedChannels[CurrentChannelIndexArr].secondUserDetail,
                })

                let sortedsubscribedChannels = store.getState().twilioChat.subscribedChannels;
                sortedsubscribedChannels.sort((a, b) => b.latestMessageTimeStamp - a.latestMessageTimeStamp);
                    
                store.dispatch({
                    type: twilioChatNamespacer('SET_SUBSCRIBED_CHANNEL'),
                    subscribedChannels: sortedsubscribedChannels,
                })

                store.dispatch({
                    type: twilioChatNamespacer('SET_ACTIVE_USER_CHAT_INDEX'),
                    activeUserChatIndex: 0,
                })
            }
            catch(error){
                console.log(error)
            }
        })
    }
}

export default new ChatClient();