import React, { Component } from "react";
import UserCard from '../../userCard/UserCard';
import FavouriteModal from '../../../containers/FavouriteModal';
import favourite from '../../../commons/images/favourite_icon.svg';
import favourite_select from '../../../commons/images/favourite-select.svg';
import formatDistanceStrict from "date-fns/formatDistanceStrict";

class NewSignUpUserCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            favouriteModal: false,
            favoriteUserId: '',
            favoriteUserName: '',
            favUnfavModalLabel: '',
            favoriteUsersIdList: '',
            isFavorite: '',
        };

        this.favouriteModalToggle = this.favouriteModalToggle.bind(this);
    }

    componentDidMount() {
        const { activityProps } = this.props;
		const feedPrefix = activityProps.activity.actor.id.split("_")[0]
        if (activityProps.activity && activityProps.activity.actor) {
            this.props.getNewSignUpUser(activityProps.activity.actor.id.substring(feedPrefix.length+1));
        }
        this.props.getFavoriteList();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.favoriteUsersList.length !== this.props.favoriteUsersList.length) {
            let favoriteUsersIdList = nextProps.favoriteUsersList.map(favoriteUser =>
                favoriteUser.userId
            );
            this.setState({favoriteUsersIdList})
        }

        if (nextProps.favRefreshRequired && nextProps.favRefreshRequired !== this.props.favRefreshRequired) {
            this.props.getFavoriteList();
            this.props.setfavRefreshRequired();
        }
    }

    favouriteModalToggle(favoriteUserId, favoriteUserName, favUnfavModalLabel, isFavorite){
        this.setState({
            favoriteUserId,
            favoriteUserName,
            favUnfavModalLabel,
            isFavorite,
            favouriteModal: !this.state.favouriteModal
        })
    }

    render() {
        const { favoriteUsersIdList, isFavorite, favoriteUserId, favoriteUserName, favUnfavModalLabel, favouriteModal } = this.state;
        const { activityProps, users, favoriteUsersList, getStreamApiKey, getStreamAppId, getStreamApiToken } = this.props;
		const feedPrefix = activityProps.activity.actor.id.split("_")[0];
        const user = activityProps.activity && activityProps.activity.actor
            ? users[activityProps.activity.actor.id.substring(feedPrefix.length+1)]
            : undefined;
        const isNewSignUpCard = activityProps.activity.title.includes('New signup for');
        const timeAgo = formatDistanceStrict(new Date(activityProps.activity.time), new Date(), { addSuffix: true });
        const newMemberName = activityProps.activity.actor && activityProps.activity.actor.data && activityProps.activity.actor.data.name
            ? activityProps.activity.actor.data.name
            : 'New Member';
        const title = isNewSignUpCard ? `Welcome, ${newMemberName}!` : activityProps.activity.title;

        return (
            <>
                {
                    user &&
                    <div className="filter-user-container selected-filter-user">
                        <div className="user-card-title-wrapper">
                            <div className="post-title-wrapper">
                                <h2 className="raf-title-text">{title}</h2>
                            </div>
                            <span className="user-card-time">{timeAgo}</span>
                        </div>
                        <div className="filter-user-basic-deatils">

                            {
                                isNewSignUpCard
                                ?
                                    <UserCard
                                        userType={user.attributes['user-type']}
                                        userDetail={user}
                                        isNewMember={true}
                                        activityProps={activityProps}
                                    />
                                :
                                    <UserCard
                                        userDetail={user}
                                        activityProps={activityProps}
                                        getStreamApiKey={getStreamApiKey}
                                        getStreamAppId={getStreamAppId}
                                        getStreamApiToken={getStreamApiToken}
                                    />
                            }

                            <div
                                className="favourite-profile favourite-profile-for-newsfeed "
                                data-tip data-for='favourites'
                                onClick={() => {
                                    this.favouriteModalToggle(
                                        user.id,
                                        `${user.attributes['first-name']} ${user.attributes['last-name']}`,
                                        'You are favoriting',
                                        favoriteUsersIdList.includes(user.id)
                                    )
                                }}
                            >
                                <img src={favoriteUsersIdList.includes(user.id) ? favourite_select : favourite}
                                     alt="no_image"/>
                            </div>
                        </div>
                    </div>
                }
                <FavouriteModal
                    isFavorite={isFavorite}
                    favoriteUserId={favoriteUserId}
                    favoriteUserName={favoriteUserName}
                    favUnfavModalLabel={favUnfavModalLabel}
                    isOpen={favouriteModal}
                    toggle={this.favouriteModalToggle}
                    favouriteModalToggle={this.favouriteModalToggle}
                    favoriteUsersList={favoriteUsersList}
                    getFavoriteList={this.props.getFavoriteList}
                />
            </>

        )
    }
}

export default NewSignUpUserCard;
