import { connect } from 'react-redux';
import { getUserData } from '../api/commonApi/getUserData'
import ThankYou from '../components/thankYou/ThankYou';
import { createNamespacer } from '../utils/reducers';
import { LOGIN, ADMINLOGIN } from '../constants/namespacer';

const loginNamespacer = createNamespacer(LOGIN);
const adminLoginNamespacer = createNamespacer(ADMINLOGIN);

const mapStateToProps = (state) => {
    return {
        isTokenValid: state.redirectAfterLogin.isTokenValid,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        getUserData: async() => {
            try{
                let response  = await getUserData();
                if(response.status===200){
                    
                } 
            }
            catch(error){
                console.log(error);   
            }
        },
        setLogout: async() => {
            dispatch({
                type: loginNamespacer('SET_IS_AUTHENTICATED'),
                isTokenValid: false,
            })
            dispatch({
                type: adminLoginNamespacer('SET_IS_ADMIN_FLAG'),
                isAdmin: false,
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThankYou);

