import React, { useState } from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import DesktopHeader from '../../containers/homePage/DesktopHeader';
import Pagination from '../shared/Pagination';

const AdminNotifications = (props) => {

    const { 
        adminNotificationList = [],
        totalRecords,
        recordsPerPage,
        getAdminNotifications,
        markAdminNotificationRead,
    } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [redirectTo, setRedirectTo] = useState(null);
    const [viewChatUser, setViewChatUser] = useState({});

    const handleCurrentPage = (page) => {
        getAdminNotifications(page);
        setCurrentPage(page);
    }

    const handleMarkAdminNotificationRead = (notification, index) => {
        const { 
            id,
            attributes: {
                msg_read, 
                student: {
                    id: userId,
                    email: userEmail,
                    name: userName,
                }
            }
        } = notification;

        // change msg status to 'read'
        if(!msg_read){
            markAdminNotificationRead(id, index);
        }

        // redirect to msg screen for both read/unread msg status
        setRedirectTo('/admin/userMessages');
        setViewChatUser({
            userId, userEmail, userName, userType: 'student', backToNotification: true
        })
    }
    
    return (
        <div>
            <DesktopHeader
                setCurrentPage={setCurrentPage}
            />
            <div className="custom-container-wrapper notifications-wrapper">
                <h2 className="title-notifications">Your Notifications</h2>
                <div className="list-notifications">
                    {
                        adminNotificationList.length === 0 ?
                            <div className="no-users no-notifications">
                                No notifications found!
                            </div>
                            : adminNotificationList.map((notification, index) => {
                                const { 
                                    id,
                                    attributes : { 
                                        msg_read,
                                        student,
                                        alumni
                                    }} = notification;
                                return (
                                    <li
                                        key={index}
                                        className={!msg_read ? "not-read" : ''}
                                        onClick={() => handleMarkAdminNotificationRead(notification, index)}
                                    >
                                        {_.capitalize(alumni.name)} and {_.capitalize(student.name)} exhanged some messages, click here to view  conversation 
                                    </li>
                                );
                            })
                    }
                </div>
            </div>
            {
                totalRecords > recordsPerPage &&
                <Pagination
                    current={currentPage}
                    total={totalRecords}
                    pageSize={recordsPerPage}
                    onChange={handleCurrentPage}
                />
            }
            {
                redirectTo ? 
                <Redirect push to={{
                    pathname: redirectTo,
                    state: {...viewChatUser}
                }} /> : null
            }
        </div>
    );
}

export default AdminNotifications;