import { connect } from 'react-redux';
import { getUserData } from '../../api/commonApi/getUserData'
import { putUserData } from '../../api/userForms/putUserData'
import EditProfileMain from '../../components/editProfile/EditProfileMain';
import { createNamespacer } from '../../utils/reducers';
import { USERFORMDATA, USERTYPE, HOMEPAGE, VALIDATIONERROR } from '../../constants/namespacer'

const userFormDataNamespacer = createNamespacer(USERFORMDATA);
const userTypeNamespacer = createNamespacer(USERTYPE);
const homePageNamespacer = createNamespacer(HOMEPAGE);
const toastErrorMsgNamespacer = createNamespacer(VALIDATIONERROR);

const mapStateToProps = (state) => {
    return {
        userType: state.userType.userType,
        profilePicUrl: state.yourInfoForm.profilePicUrl,
        name: state.yourInfoForm.name,
        lastName: state.yourInfoForm.lastName,
        email: state.yourInfoForm.email,
        linkedinProfileUrl: state.yourInfoForm.linkedinProfileUrl,
        mbaProgram: state.yourInfoForm.mbaProgram,
        colOrUniv: state.yourInfoForm.colOrUniv,
        graduationYear: state.yourInfoForm.graduationYear,
        currentWorkLocation: state.yourInfoForm.currentWorkLocation,
        concept: state.yourVentureForm.concept,
        venture: state.yourVentureForm.venture,
        needs: state.yourVentureForm.needs,
        feasibilityStudyAmount: state.yourVentureForm.feasibilityStudyAmount,
        investorType: state.yourVentureForm.investorType,
        additionalDetails: state.yourVentureForm.additionalDetails,
        sectors: state.yourSectorForm.sectors,
        oldSectorsForEditProfile: state.yourSectorForm.oldSectorsForEditProfile,
        isTokenValid: state.redirectAfterLogin.isTokenValid,
        additionalDetails: state.yourSectorForm.additionalDetails,
        fieldsMissing: state.validationError.fieldsMissing,
        isEmailNotificationOn: state.yourInfoForm.isEmailNotificationOn,
        profilePicBase64: state.yourInfoForm.profilePicBase64,
        loading: state.yourInfoForm.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        getUserData: async() => {
            try{
                let response  = await getUserData();
                if(response.status===200){
                    const { attributes } = response.data.data;
                
                    const userType = attributes['user-type'];
                    const profilePic = attributes['image-url'];
                    const firstName = attributes['first-name'];
                    const lastName = attributes['last-name'];
                    const email = attributes.email;
                    const linkedinProfileUrl = attributes['profile-url'];
                    const mbaProgram = attributes['mba-program'];
                    const isEmailNotificationOn = attributes['email-notification?'];
                    let colOrUniv;
                    if(attributes.university){
                        colOrUniv = attributes.university;
                    }
                    const graduationYear = attributes['grad-year'];
                    let currentWorkLocation;
                    if(attributes.location){
                        currentWorkLocation = attributes.location
                    }
                    let concept, venture, needs, additionalDetails
                    if(userType==='alumni'){
                        concept = attributes['alumni-detail'].concept;
                        venture = attributes['alumni-detail'].state;
                        needs = attributes['alumni-detail']['need-today'];
                    }else if(userType==='student' || userType=='advisor'){
                        additionalDetails = attributes['interest-desc']
                    }
                    const sectors = attributes.interests;

                    dispatch({
                        type: userTypeNamespacer('SET_USERTYPE_VALUE'),
                        userType: userType
                    })
                    
                    dispatch({
                        type: userFormDataNamespacer('SET_PROFILE_PIC_URL'),
                        profilePicUrl: profilePic
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_NAME_VALUE'),
                        name: firstName
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_LAST_NAME_VALUE'),
                        lastName: lastName
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_EMAIL_VALUE'),
                        email: email
                    })
        
                    dispatch({
                        type: userFormDataNamespacer('SET_LINKEDIN_PROFILE_URL_VALUE'),
                        linkedinProfileUrl: linkedinProfileUrl,
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_MBA_PROGRAM_VALUE'),
                        mbaProgram: mbaProgram
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_COL_OR_UNIV_VALUE'),
                        colOrUniv: colOrUniv
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_GRADUATION_YEAR_VALUE'),
                        graduationYear: graduationYear
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_EMAIL_NOTIFICATION_ON_VALUE'),
                        isEmailNotificationOn: isEmailNotificationOn
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_CURRENT_WORK_LOCATION_VALUE'),
                        currentWorkLocation: currentWorkLocation
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_CONCEPT_VALUE'),
                        concept: concept
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_VENTURE_VALUE'),
                        venture: venture
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_NEEDS_VALUE'),
                        needs: needs
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_SECTORS_VALUE'),
                        sectors: sectors
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_OLD_SECTORS_VALUE'),
                        oldSectorsForEditProfile: sectors,
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_ADDITIONAL_DETAILS'),
                        additionalDetails: additionalDetails
                    })

                    dispatch({
                        type: homePageNamespacer('SET_ACTIVE_SCREEN_VALUE'),
                        activeScreen: 'userProfile'
                    })
        
                } 
            }
            catch(error){
                console.log(error)
            }
        },
        saveEditProfileData: async(params) => {
            try{
                const { userType, name, lastName, email, linkedinProfileUrl, mbaProgram, colOrUniv, graduationYear, currentWorkLocation,
                    concept, venture, needs, sectors, feasibilityStudyAmount, investorType, oldSectorsForEditProfile, additionalDetails, isEmailNotificationOn, profilePicUrl } = params;

                let bodyFormData = new FormData();

                let colOrUnivId = typeof(colOrUniv)==='number' ? colOrUniv : colOrUniv.id;
                let currentWorkLocationId = typeof(currentWorkLocation)==='number' ? currentWorkLocation : currentWorkLocation.id;

                let addSectorIdArray = sectors.map(sector => Number(sector))
                let remSectorIdArray = oldSectorsForEditProfile.map(sector => {
                    return{
                        id: sector.id,
                        interestId : sector.user_interest_id
                    }
                })
                
                let valid = false;
                let reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
                valid = (name && lastName && email && mbaProgram && colOrUniv && graduationYear && currentWorkLocation) ? 
                (
                    reg.test(email) === false ? this.props.setFieldsMissing('Valid Email Address') : true
                ) : false;

                if(userType==='alumni'){
                    valid = (concept && venture && needs) ? true : false;
                }
                valid = sectors.length>0 ? true : false;

                if(!valid){
                    alert('All fields are mandatory.');
                    return;
                }
                else{
                    bodyFormData.append('user[first_name]', name );
                    bodyFormData.append('user[last_name]', lastName );
                    bodyFormData.append('user[email]', email );
                    bodyFormData.append('user[profile_url]', linkedinProfileUrl);
                    bodyFormData.append('user[grad_year]', graduationYear );
                    bodyFormData.append('user[mba_program]', mbaProgram);
                    bodyFormData.append('user[university_id]', colOrUnivId);
                    bodyFormData.append('user[location_id]', currentWorkLocationId);
                    bodyFormData.append('user[email_notification?]', isEmailNotificationOn);

                    if(profilePicUrl && typeof profilePicUrl === "object"){
                        bodyFormData.append('user[photo]', profilePicUrl);
                    }

                    if(userType === 'alumni'){
                        bodyFormData.append('user[alumni_detail_attributes][concept]', concept); 
                        bodyFormData.append('user[alumni_detail_attributes][state]', venture ); 
                        if (needs.constructor === Array) {
                            for (let i = 0; i < needs.length; i++) {
                                const needToday = needs[i];
                                if (!needToday.includes('|')) {
                                    bodyFormData.append('user[alumni_detail_attributes][need_today][]', needToday);
                                } else if (needToday.includes('|') && needToday.split('|')[0] === 'feasibility_study') {
                                    bodyFormData.append('user[alumni_detail_attributes][need_today][]', needToday.split('|')[0] + '|' + feasibilityStudyAmount);
                                // } else if (needToday.includes('|') && needToday.split('|')[0] === 'advisor') {
                                //     bodyFormData.append('user[alumni_detail_attributes][need_today][]', needToday.split('|')[0] + '|' + investorType);
                                }
                            }
                        }
                        else {
                            bodyFormData.append('user[alumni_detail_attributes][need_today][]', needs);
						}
					}
                    else if(userType==='student' || userType === 'advisor'){
                        bodyFormData.append('user[interest_desc]', additionalDetails);
                    }
                    
                    addSectorIdArray = addSectorIdArray.map((sector) => {
                        return {
                            sector,
                            addRequired: true
                        }
                    })

                    remSectorIdArray = remSectorIdArray.map((sector) => {
                        return {
                            ...sector,
                            remRequired: true
                        }
                    })

                    for(let i=0; i<addSectorIdArray.length; i++){
                        for(let j=0; j<remSectorIdArray.length; j++){
                            if(addSectorIdArray[i].sector===remSectorIdArray[j].id){
                                addSectorIdArray[i].addRequired = false;
                                remSectorIdArray[j].remRequired = false;
                            }
                        }
                    }

                    if(oldSectorsForEditProfile !== sectors){
                        let index = 0;
                        for(let i=0; i<remSectorIdArray.length; i++){
                            if(remSectorIdArray[i].remRequired){
                                bodyFormData.append(`user[user_interests_attributes][${index}][_destroy]`, true);
                                bodyFormData.append(`user[user_interests_attributes][${index}][id]`,remSectorIdArray[i].interestId)
                                index++;
                            }
                        }
                        for(let i=0; i<addSectorIdArray.length; i++){
                            if(addSectorIdArray[i].addRequired){
                                bodyFormData.append(`user[user_interests_attributes][${index}][interest_id]`, addSectorIdArray[i].sector);
                                index++;
                            }
                        }
                    }
                }
                dispatch({
                    type: userFormDataNamespacer('SET_LOADER_VALUE'),
                    loading: true
                })
                await putUserData(bodyFormData);
            }
            catch(error){
                console.log(error)
            }
            finally{
                dispatch({
                    type: userFormDataNamespacer('SET_LOADER_VALUE'),
                    loading: false
                })
            }
        },
        setFieldsMissing: (value) => {
            dispatch({
                type: toastErrorMsgNamespacer('SET_FIELDMISSING'),
                fieldsMissing: value
            })
        },
        setProfilePic: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_PROFILE_PIC_URL'),
                profilePicUrl: value
            })
        },
        setProfilePicBase64: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_PROFILE_PIC_BASE64'),
                profilePicBase64: value
            })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileMain);
