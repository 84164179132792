import { connect } from 'react-redux';
import { createNamespacer } from '../../utils/reducers'
import { HOMEPAGE, LOGIN , ADMINLOGIN, TWILIOCHAT, ADMINHOMEPAGE, PAGINATION, USERFORMDATA } from '../../constants/namespacer'
import DesktopHeader from '../../components/homePage/desktopHeader/DesktopHeader'
import { getUserData } from '../../api/commonApi/getUserData'
import { getUserListForAdminApi } from '../../api/adminHomePage'
import { getSecondUserDetailApi } from '../../api/twilioChat'
import { getAdminNotificationListAPI } from '../../api/adminNotifications';
import chatClient from '../../services/twilio';
import { getMessageCount } from '../../constants/fixedValues'

const homePageNamespacer = createNamespacer(HOMEPAGE);
const loginNamespacer = createNamespacer(LOGIN);
const adminLoginNamespacer = createNamespacer(ADMINLOGIN)
const twilioChatNamespacer = createNamespacer(TWILIOCHAT)
const adminHomepageNamespacer = createNamespacer(ADMINHOMEPAGE);
const paginationNamespacer = createNamespacer(PAGINATION);
const userFormDataNamespacer = createNamespacer(USERFORMDATA);

const mapStateToProps = (state) => {
    return {
        activeScreen: state.desktopHeader.activeScreen,
        newNotification: state.desktopHeader.newNotification,
        userData: state.redirectAfterLogin.userData,
        isTokenValid: state.redirectAfterLogin.isTokenValid,
        isAdmin: state.adminLogin.isAdmin,
        msgFromIdentity: state.twilioChat.msgFromIdentity,
        subscribedChannels: state.twilioChat.subscribedChannels,
        secondUserData: state.twilioChat.secondUserData,
        newMessageFromCount: state.twilioChat.newMessageFromCount,
        adminNotificationList: state.desktopHeader.adminNotificationList,
        profilePicBase64: state.yourInfoForm.profilePicBase64,
        profilePicUrl: state.yourInfoForm.profilePicUrl,
        profilePicBase64Copy: state.yourInfoForm.profilePicBase64Copy,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setActiveScreen: (value, profilePicBase64) => {
            dispatch({
                type: homePageNamespacer('SET_ACTIVE_SCREEN_VALUE'),
                activeScreen: value
            })
            dispatch({
                type: userFormDataNamespacer('SET_PROFILE_PIC_BASE64'),
                profilePicBase64: profilePicBase64
            })
        },
        setNewNotification: () => {
            dispatch({
                type: homePageNamespacer('SET_NEW_NOTIFICATION_VALUE'),
                newNotification: true,
            })
        },
        getUserData: async() => {
            try{
                let response  = await getUserData();
                if(response.status===200){
                    dispatch({
                        type: loginNamespacer('SET_USER_DATA'),
                        userData: response.data.data
                    })
                } 
            }
            catch(error){
                console.log(error)   
            }
        },
        setLogout: async() => {
            dispatch({
                type: loginNamespacer('SET_IS_AUTHENTICATED'),
                isTokenValid: false,
            })
            dispatch({
                type: adminLoginNamespacer('SET_IS_ADMIN_FLAG'),
                isAdmin: false,
            })
            dispatch({
                type: homePageNamespacer('SET_ACTIVE_SCREEN_VALUE'),
                activeScreen: 'homepage'
            })

        },
        clearNewMessageFromCount: () => {
            dispatch({
                type: twilioChatNamespacer('SET_NEW_MSG_FROM_COUNT'),
                newMessageFromCount: 0,
            })
        },
        setPendingUserType: async(value) => {
            dispatch({
                type: adminHomepageNamespacer('SET_PENDING_USER_TYPE'),
                pendingUserType: value,
            })
        },
        getPendingUserData: async(userStatus, userType) => {
            try{
                let response = await getUserListForAdminApi(userStatus, userType);
                if(response.status===200){
                    dispatch({
                        type: adminHomepageNamespacer('SET_PENDING_USER_LIST'),
                        pendingUserList: response.data.data
                    })
                    dispatch({
                        type: paginationNamespacer('SET_TOTAL_RECORDS'),
                        totalRecords: response.data.meta['total-records']
                    })
                }
            }
            catch(error){
                console.log(error)  
            }
        },
        get getUserSubscribedChannels(){
            return async (msgFromIdentity) => {
                try{
                    const subscribedChannels = await chatClient.client.getSubscribedChannels();
                    const channelWithOtherDetails = await this.getChannelWithOtherDetails(subscribedChannels.items, msgFromIdentity);
                    const filterChannelWithOtherDetails = channelWithOtherDetails.filter(channel => {
                        return channel.secondUserDetail !== undefined && channel.latestMessage !== undefined;
                    })
                    
                    filterChannelWithOtherDetails.sort((a, b) => b.latestMessageTimeStamp - a.latestMessageTimeStamp);
                    dispatch({
                        type: twilioChatNamespacer('SET_SUBSCRIBED_CHANNEL'),
                        subscribedChannels: filterChannelWithOtherDetails,
                    })
                    
                    let newMessageFromCount = 0;
                    filterChannelWithOtherDetails.map(channel => channel.newMessage ? newMessageFromCount++ : null)
                    
                    dispatch({
                        type: twilioChatNamespacer('SET_NEW_MSG_FROM_COUNT'),
                        newMessageFromCount: newMessageFromCount,
                    })
                }
                catch(error){
                    console.log(error)
                }
            }
        },
        get getChannelWithOtherDetails(){
            return async (subscribedChannelsArr, msgFromIdentity) => {
                try{
                    return await Promise.all(subscribedChannelsArr.map(async (channel) => {
                        const secondUserId = channel.uniqueName.split('-').filter((userId) => (
                            userId !== msgFromIdentity
                        ))

                        let secondUserDetail;
                        if (secondUserId.length === 1){
                            secondUserDetail = await this.getSecondUserDetail(secondUserId[0], false);
                        }
                        
                        const messages = await channel.getMessages(getMessageCount);
                        const totalMessagesCountOnChannel = messages.items.length;

                        let latestMessage;
                        let latestMessageTimeStamp;
                        let newMessage = false;

                        if(totalMessagesCountOnChannel>0){
                            latestMessage = messages.items[totalMessagesCountOnChannel-1].body
                            latestMessageTimeStamp = messages.items[totalMessagesCountOnChannel-1].timestamp
                            newMessage = channel.lastMessage.index - channel.lastConsumedMessageIndex > 0;
                        }
                        return({channel, secondUserDetail, messages, latestMessage, latestMessageTimeStamp, newMessage})
                    }))
                }
                catch(error){
                    console.log(error)
                }
            }
        },
        get getSecondUserDetail(){
            return async (secondUserId, setReduxState) => {
                try{
                    let response  = await getSecondUserDetailApi(secondUserId);
                    if(response.status===200){
                        if(setReduxState){
                            dispatch({
                                type: twilioChatNamespacer('SET_SECOND_USER_DATA'),
                                secondUserData: response.data.data,
                            })
                        }
                        else{
                            return response.data.data;
                        }
                    } 
                }
                catch(error){
                    console.log(error)
                }
            }
        },
        get getAdminNotifications(){
            return async () => {
                try {
                    let response  = await getAdminNotificationListAPI();
                    if(response.status===200){
                        dispatch({
                            type: adminLoginNamespacer('SET_ADMIN_NOTIFICATION'),
                            adminNotificationList: response.data.data,
                        })
                        dispatch({
                            type: paginationNamespacer('SET_TOTAL_RECORDS'),
                            totalRecords: response.data.meta['total-records']
                        })
                    } 
                }
                catch(error){
                    console.log(error);
                }            
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DesktopHeader);

