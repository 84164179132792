import React, { Component } from "react";
import profilePic from "../../commons/images/profile_pic.svg"
import moment  from 'moment'

const getTimeOfMsg = (timestamp) => {
    return moment(timestamp).format('MM/DD/YYYY');
}

class AdminExistingUserMessagesList extends Component {

    componentDidMount(){
        this.props.setActiveUserChatIndex(0);
    }

    handleUserChannel = (channelDetail, viewUserIndex) => {
        this.props.setActiveUserChatIndex(viewUserIndex);
    }

    render() {
        const { subscribedChannels, activeUserChatIndex } = this.props;
        return(
            <>
                <ul className="message-list-wrapper">
                    {
                        subscribedChannels.length>0 ? 
                        subscribedChannels.map((channelDetail, index) => (
                            <li 
                                key={index} 
                                className={`message-block unseen-notification
                                ${ activeUserChatIndex===index ? 'view-user' : ''}`}
                            >
                                <div 
                                    onClick={() => {this.handleUserChannel(channelDetail, index)}}
                                    className="msg-details-section"
                                >
                                    <div className="msg-profile">
                                        <img 
                                            src={channelDetail.secondUserDetail ? 
                                                channelDetail.secondUserDetail.attributes['image-url'] ? 
                                                channelDetail.secondUserDetail.attributes['image-url'] : profilePic
                                                : profilePic
                                            }
                                            alt="no_image"
                                        /> 
                                    </div>
                                    <div className="msg-details">
                                        {
                                            channelDetail.secondUserDetail ? 
                                            <span>{channelDetail.secondUserDetail.attributes['first-name']} {channelDetail.secondUserDetail.attributes['last-name']}</span> : ''
                                        }
                                        <span>{channelDetail.latestMessage}</span>
                                    </div>
                                </div>
                                {
                                    channelDetail.channel.lastMessage.index - channelDetail.channel.lastConsumedMessageIndex > 0 ?
                                    <span>{getTimeOfMsg(channelDetail.channel.lastMessage.timestamp.toString())}</span>
                                    : <span>{getTimeOfMsg(channelDetail.channel.lastMessage.timestamp.toString())}</span>
                                }
                            </li>
                        )) : ''
                    }
                </ul>
            </>
        );
    }
}

export default AdminExistingUserMessagesList;