import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { Button } from 'reactstrap';
import 'react-activity-feed/dist/index.css';
import DesktopHeader from '../../containers/homePage/DesktopHeader';
import MobileMenu from '../../containers/homePage/MobileMenu';
import NewPostModal from '../newPostModal/NewPostModal';
import UserNewsFeed from '../../containers/UserNewsFeed';
import searchicon from '../../commons/images/searchLoop.svg';
import Spinner from '../spinner/Spinner'

class NewsFeed extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectTo: null,
			isNewPostModalOpen: false,
		};

		this.newPostModalToggle = this.newPostModalToggle.bind(this);
	}

    componentDidMount() {
        this.props.checkInitialNewsFeedSettings();
        this.props.getUserData();
	}

	newPostModalToggle() {
		this.setState({
			isNewPostModalOpen: !this.state.isNewPostModalOpen
		});
	}

	handleClickAddPost() {
		this.newPostModalToggle();
	}

    handleSendComment() { }

	render() {
		const {
			tcClient,
			newMessageFromCount,
			getStreamApiKey,
			getStreamAppId,
			getStreamApiToken,
			getStreamFeedName,
			getStreamNotificationFeedName,
		} = this.props;
		const { redirectTo } = this.state;

		return (
			<React.Fragment>
				<DesktopHeader tcClient={tcClient} newMessageFromCount={newMessageFromCount} />
				<Spinner/>

				{getStreamApiKey && getStreamAppId && getStreamApiToken && getStreamFeedName &&
					<div>
                        <div className="newsfeed-wrapper">
                            <div className="newsfeed-wrapper-manage">
                                <div className="newsfeed-wrapper-manage-sidebar">
                                    <div className="sidebar-title">
                                        <span>Filters</span>
                                    </div>

                                    <div className="newsfeed-wrapper-manage-sidebar-close">
                                        <Button className="closeSidebarBtn" id="newsfeed-wrapper-sidebar">
                                            icon
                                        </Button>
                                    </div>

                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="newsfeed-wrapper-manage-search">
                                                <div className="newsfeed-wrapper-manage-search-input">
                                                    <div className="newsfeed-wrapper-manage-search-input-icon">
                                                        <img src={searchicon} alt="" />
                                                    </div>
                                                    <input type="text" id="newsfeed-wrapper-search-form" name="newsfeed-wrapper-search-form" placeholder="Search"
                                                           className="newsfeed-wrapper-search-form" autoComplete="off" />
                                                </div>
                                                <Button color="primary" onClick={() => { this.handleClickAddPost() }} className="add-post-btn">
                                                    <span>Add Post</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <UserNewsFeed/>
                        </div>
                        <MobileMenu tcClient={tcClient} newMessageFromCount={newMessageFromCount} />
                        { redirectTo ? <Redirect to={redirectTo} /> : null}
                        <NewPostModal
                            isOpen={this.state.isNewPostModalOpen}
                            toggle={this.newPostModalToggle}
                            apiKey={getStreamApiKey}
                            appId={getStreamAppId}
                            token={getStreamApiToken}
							userId={getStreamFeedName}
							notificationFeedName={getStreamNotificationFeedName}
                        />
					</div>
                }
			</React.Fragment>
		)
	}
}

export default NewsFeed;