import React from 'react';
import {
    FormGroup,
    Input,
} from 'reactstrap'
;
function FormGroupinputBox(props) {
    return (
        <React.Fragment>
            <FormGroup>
                <Input
                    type={props.type}
                    placeholder={props.placeholder}
                    className={`form_control ${
                                props.isValid
                                ? ''
                                : 'borderRed'
                    }`}
                    onChange={props.onChange}
                />
            </FormGroup>
            <div
                className={props.isValid
                            ? 'success'
                            : 'error'
                        }
            >
                {props.invalidMessage}
            </div>
        </React.Fragment>
    )
}

export default FormGroupinputBox;
