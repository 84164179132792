import { createReducer, createNamespacer } from '../utils/reducers';
import { TWILIOCHAT } from '../constants/namespacer';

const initialState = {
    twilioToken: '',
    msgFromIdentity: '',
    messages: [],
    sendMessageText: '',
    tcCurrentChannel: null,
    subscribedChannels: [],
    secondUserData: null,
    activeUserChatIndex: 0,
    newMessageFromCount: 0,
}

const twilioChatNamespacer = createNamespacer(TWILIOCHAT);

const handlers = {
    [twilioChatNamespacer('SET_TWILIO_TOKEN')]: (state, action) => {
        return {
            ...state,
            twilioToken: action.twilioToken,
        }
    },
    [twilioChatNamespacer('SET_MSG_FROM_IDENTITY')]: (state, action) => {
        return {
            ...state,
            msgFromIdentity: action.msgFromIdentity,
        }
    },
    [twilioChatNamespacer('SET_MESSAGES')]: (state, action) => {
        return {
            ...state,
            messages: action.messages,
        }
    },
    [twilioChatNamespacer('SET_SEND_MSG_TEXT')]: (state, action) => {
        return {
            ...state,
            sendMessageText: action.sendMessageText,
        }
    },
    [twilioChatNamespacer('SET_TC_CURRENT_CHANNEL')]: (state, action) => {
        return {
            ...state,
            tcCurrentChannel: action.tcCurrentChannel,
        }
    },
    [twilioChatNamespacer('SET_SUBSCRIBED_CHANNEL')]: (state, action) => {
        return {
            ...state,
            subscribedChannels: action.subscribedChannels,
        }
    },
    [twilioChatNamespacer('SET_SECOND_USER_DATA')]: (state, action) => {
        return{
            ...state,
            secondUserData: action.secondUserData,
        }
    },
    [twilioChatNamespacer('SET_ACTIVE_USER_CHAT_INDEX')]: (state, action) => {
        return{
            ...state,
            activeUserChatIndex: action.activeUserChatIndex,
        }
    },
    [twilioChatNamespacer('SET_NEW_MSG_FROM_COUNT')]: (state, action) => {
        return{
            ...state,
            newMessageFromCount: action.newMessageFromCount,
        }
    }
    
}

const twilioChatReducer = createReducer(initialState, handlers, [TWILIOCHAT]);

export default twilioChatReducer;