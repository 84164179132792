import { createReducer, createNamespacer } from '../utils/reducers';
import { USERTYPE } from '../constants/namespacer';

const initialState = {
    userType: '',
    isInvestor: false,
}

const namespacer = createNamespacer(USERTYPE);

const handlers = {
    [namespacer('SET_USERTYPE_VALUE')]: (state, action) => {
        return {
            ...state,
            userType: action.userType,
        }
    },
    [namespacer('SET_IS_INVESTOR_VALUE')]: (state, action) => {
        return {
            ...state,
            isInvestor: action.isInvestor,
        }
    },
}

const userTypeReducer = createReducer(initialState, handlers, [USERTYPE]);

export default userTypeReducer;