import { connect } from 'react-redux';
import NewsFeed from '../components/newsFeed/NewsFeed';
import { createNamespacer } from '../utils/reducers';
import { NEWSFEED, USERFORMDATA } from '../constants/namespacer';
import {getUserData} from "../api/commonApi/getUserData";

const newsFeedNamespacer = createNamespacer(NEWSFEED);
const userFormDataNamespacer = createNamespacer(USERFORMDATA);

const mapStateToProps = (state) => {
    return {
        newsFeedList: state.newsFeed.newsFeedList,
        getStreamApiKey: state.newsFeed.getStreamApiKey,
        getStreamAppId: state.newsFeed.getStreamAppId,
        getStreamApiToken: state.newsFeed.getStreamApiToken,
        getStreamFeedName: state.newsFeed.getStreamFeedName,
        getStreamNotificationFeedName: state.newsFeed.getStreamNotificationFeedName,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setNewsFeedList: (value) => {
            dispatch({
                type: newsFeedNamespacer('SET_NEWS_FEED_LIST'),
                newsFeedList: value
            });
        },
        checkInitialNewsFeedSettings: async() => {
            const apiKey = localStorage.getItem('getStreamApiKey');
            const appId = localStorage.getItem('getStreamAppId');
            const token = localStorage.getItem('getStreamApiToken');
            const feedName = localStorage.getItem('getStreamFeedName');
            const notificationFeedName = localStorage.getItem('getStreamNotificationFeedName');

            dispatch({
                type: newsFeedNamespacer('SET_GETSTREAM_API_KEY'),
                getStreamApiKey: apiKey
            });
            dispatch({
                type: newsFeedNamespacer('SET_GETSTREAM_API_ID'),
                getStreamAppId: appId
            });
            dispatch({
                type: newsFeedNamespacer('SET_GETSTREAM_API_TOKEN'),
                getStreamApiToken: token
            });
            dispatch({
                type: newsFeedNamespacer('SET_GETSTREAM_FEED_NAME'),
                getStreamFeedName: feedName
            });
            dispatch({
                type: newsFeedNamespacer('SET_GETSTREAM_NOTIFICATION_FEED_NAME'),
                getStreamNotificationFeedName: notificationFeedName
            });
        },
        getUserData: async() => {
            try {
                let response = await getUserData();
                if (response.status === 200) {
                    const { attributes } = response.data.data;

                    const profilePic = attributes['image-url'];

                    dispatch({
                        type: userFormDataNamespacer('SET_PROFILE_PIC_URL'),
                        profilePicUrl: profilePic
                    })
                }
            }
            catch(error) {
                console.log(error);
            }
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsFeed);
