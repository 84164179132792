import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { SinglePost, CommentList, CommentField, Title } from "react-activity-feed";

class AddCommentModal extends Component {
    render() {
        const { activity, isOpen, toggle } = this.props;
        const userId = this.props.activity.actor.id;
        const isNewMember = activity.title.includes('New signup for');
        const name = activity.actor && activity.actor.data && activity.actor.data.name ? activity.actor.data.name : '';
        const title = isNewMember ? `${activity.title.trim()} ${name}` : activity.title;

        return (
            <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered modal-xl add-comment-modal">
                <ModalHeader toggle={toggle} className="modal-head">
                    {title}
                </ModalHeader>
                <ModalBody className="modal-body">
                    <div className="comments-wrapper">
                        <SinglePost
                            activityId={activity.id}
                            feedGroup="user"
                            userId={userId}
                            Activity={(props) => (
                                <div className="card-wrapper">
                                    <div className="raf-activity__content">
                                        <div style={{ padding: "8px 16px 8px 0" }}>
                                            <p>{activity.text}</p>
                                        </div>
                                    </div>
                                    <div className="post-title-wrapper">
                                        <Title>
                                            <h2 className="raf-title-text">Comments</h2>
                                        </Title>
                                    </div>
                                    <CommentList activityId={props.activity.id} />
                                    <CommentField activity={props.activity} onAddReaction={props.onAddReaction} />
                                </div>
                            )}
                        />
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default AddCommentModal;
