import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter, Form, FormGroup, Label, Input } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import SubscribeModal from "../subscribeModal/SubscribeModal";
import FAQPage from "../faqPage/FAQPage";

class SchoolsModal extends Component {
    state = {
        email: "",
        subscribeModal: false,
    };

    componentDidMount() {
        this.props.getSchoolList();
    }

    fetchData = () => {
        this.props.getSchoolList();
    };

    handleEmail = (evt) => {
        this.setState({ email: evt.target.value });
    };

    handleSubscribe = () => {
        let reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(this.state.email) === false) {
            alert("Invalid Email Address");
        } else {
            this.props.setEmailSubscription(this.state.email);
            this.subscribeModalToggle();
        }
    };

    subscribeModalToggle = () => {
        this.setState({
            subscribeModal: !this.state.subscribeModal,
        });
    };

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="aboutUs-modal schools-modal">
                <ModalHeader toggle={this.props.toggle} className="modal-head">
                    <h3>Who we are</h3>
                </ModalHeader>

                <ModalBody className="about-details" id="scrollable-div">
                    <div className="faq-wrapper screen-height">
                        <div className="custom-profile-container faq-container">
                            <div className="faq-content">
                                <h4 className="faq-subtitle">Who runs GradShack?</h4>
                                <p className="faq-desc">
                                    GradShack was created and is managed by the principals of Collaborative Capital,
                                    LLC, a New York based investment firm. The activities of GradShack are separate from
                                    the activities of Collaborative Capital, though Collaborative Capital is not
                                    precluded from investing in ventures arising from the GradShack platform.
                                </p>
                            </div>
                            <div className="faq-content">
                                <h4 className="faq-subtitle">Is GradShack officially endorsed by the schools?</h4>
                                <p className="faq-desc">
                                    No, we have not sought any official partnerships with schools, though we enjoy
                                    collaborating with them. GradShack is an independent platform.
                                </p>
                            </div>
                            <div className="faq-content">
                                <h4 className="faq-subtitle">Are there any costs I should be aware of?</h4>
                                <p className="faq-desc">
                                    No, GradShack does not charge fees or request equity ownership.
                                </p>
                            </div>
                            <div className="faq-content">
                                <h4 className="faq-subtitle">So, what’s in it for GradShack?</h4>
                                <p className="faq-desc">
                                    We are passionate about unearthing entrepreneurial talent, fostering
                                    intergenerational collaboration, enhancing alumni engagement and supporting
                                    transformational ventures.
                                </p>
                            </div>
                        </div>
                    </div>
                </ModalBody>

                {/* <ModalFooter>
					<p className="footer-text">
						Join our email list to receive updates on which schools we're adding next!
					</p>
					<Form>
						<FormGroup>
							<Label>Enter your Email </Label>
							<div className="modal-footer-email">
								<Input
									type="email"
									name="email"
									placeholder="user@example.com"
									onChange={this.handleEmail}
								/>
								<Button onClick={this.handleSubscribe}>Subscribe</Button>
							</div>
						</FormGroup>
					</Form>
				</ModalFooter>
				<SubscribeModal isOpen={this.state.subscribeModal} toggle={this.subscribeModalToggle} /> */}
            </Modal>
        );
    }
}

export default SchoolsModal;
