import React, { Component } from 'react';
import _ from 'lodash';
import { Modal, ModalHeader, ModalBody, Input, CustomInput, Label, Collapse, Button } from 'reactstrap';
import { StreamApp, StatusUpdateForm } from 'react-activity-feed';
import { newPostTypeList } from '../../constants/newPostTypeList'
import YourSectorForm from '../../containers/userForms/YourSectorForm';

class NewPostModal extends Component {
	constructor(props) {
		super(props);
		const postTypeList = {};
		newPostTypeList.forEach(postType => {
			postTypeList[postType.attributes.id] = false;
		});
		this.state = {
			postTypeList: postTypeList,
			postTitle: '',
			titlePlaceHolder: '',
			isOtherTypeChecked: false,
			otherTypeText: '',
			isAutoPostTitle: true,
			isManualPostTitle: false,
			sectors: [],
			isSectorsSectionOpened: false,
			sectorsNote: 'Your selected sector(s) below will help categorize your post but will not appear in your post. ' +
			'Consider including your sector focus in your Post Title and/or Post Content.',
			raiseFundsNote: 'You should not raise funds on this site.',
			sectorsTitle: '',
		};

		this.onSetSectors = this.onSetSectors.bind(this);
	}

	updateTitlePlaceholder({ newTypes, otherTypeText, isOtherTypeChecked }) {
		let placeholder = '';
		const types = newTypes ? _.cloneDeep(newTypes) : _.cloneDeep(this.state.postTypeList);
		newPostTypeList.forEach(postType => {
			if (types[postType.attributes.id]) {
				placeholder += (placeholder === '' ? ' ' : ', ') + postType.attributes.name;
			}
		});

		const otherTypeChecked = isOtherTypeChecked !== undefined ? isOtherTypeChecked : this.state.isOtherTypeChecked;

		if (otherTypeChecked) {
            placeholder += (placeholder === '' ? ' ' : ', ') + (otherTypeText || this.state.otherTypeText || 'Other');
		}
		this.setState({ titlePlaceHolder: placeholder });
	}

	handlePostTitleChange(e) {
		this.setState({ postTitle: e.target.value });
	};
	
	handleOtherTypeTextChange(e) {
		this.setState({ otherTypeText: e.target.value });
		this.updateTitlePlaceholder({ otherTypeText: e.target.value });
	}

	handleTypeToggle(type) {
		const { postTypeList } = this.state;
		const newTypes = {
			...postTypeList,
			[type]: !postTypeList[type],
		};
		this.updateTitlePlaceholder({ newTypes });
		this.setState({ postTypeList: newTypes });
	};
	
	handleOtherTypeToggle() {
		if (this.state.isOtherTypeChecked) {
			this.setState({ otherTypeText: '' });
		}
		this.setState({
			isOtherTypeChecked: !this.state.isOtherTypeChecked
		});
		this.updateTitlePlaceholder({
			isOtherTypeChecked: !this.state.isOtherTypeChecked,
            otherTypeText: this.state.isOtherTypeChecked ? '' : this.state.otherTypeText,
		});
	}

	modifyActivityData(data) {
		const {
			postTitle,
			postTypeList,
			isAutoPostTitle,
			titlePlaceHolder,
			isOtherTypeChecked,
			otherTypeText,
			sectors,
		} = this.state;

		const types = newPostTypeList.map(postType => {
			if (postTypeList[postType.attributes.id]) {
				return postType.attributes.id;
			}
		});

		if (isOtherTypeChecked && otherTypeText.length) {
			types.push(otherTypeText);
		}

		const title = isAutoPostTitle ? titlePlaceHolder : postTitle;

        if (window.hasOwnProperty('gtag')) {
            window.gtag('event', 'new_post', {
                event_category: 'News Feed',
                event_label: 'New Post',
                value: title,
            });
        }

		// new activity data
		return {
			...data,
			title,
            sectors,
			types: _.compact(types),
		};
	}
	
	handleIsAutoPostTitleToggle() {
		this.setState({
			isAutoPostTitle: !this.state.isAutoPostTitle,
            isManualPostTitle: this.state.isAutoPostTitle,
		});
	}

	handleIsManualPostTitleToggle() {
        this.setState({
			isManualPostTitle: !this.state.isManualPostTitle,
            isAutoPostTitle: this.state.isManualPostTitle,
        });
	}

	onSetSectors(sectors, sectorList) {
		this.setState({ sectors: sectors });

		let sectorsTitle = '';
        sectorList.forEach(sector => {
        	if (sectors.indexOf(sector.id) > -1) {
                sectorsTitle += sectorsTitle === '' ? sector.attributes.name : ', ' + sector.attributes.name;
			}
		});

		this.setState({ sectorsTitle: sectorsTitle });
	}

	toggleSectorSection() {
		this.setState({ isSectorsSectionOpened: !this.state.isSectorsSectionOpened });
	}

	render() {
        const { apiKey, appId, token } = this.props;
		const {
			postTypeList,
			titlePlaceHolder,
			isOtherTypeChecked,
			isAutoPostTitle,
			isManualPostTitle,
			otherTypeText,
            postTitle,
            isSectorsSectionOpened,
            sectorsNote,
			raiseFundsNote,
            sectorsTitle,
		} = this.state;
		const otherTextProps = { invalid: false };
		const postTitleProps = { invalid: isManualPostTitle && postTitle.length === 0 };

		return (
			<Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="modal-dialog-centered modal-xl new-post-modal">
				<ModalHeader toggle={this.props.toggle} className="modal-head">
					This post relates to (check at least one):
                </ModalHeader>
				<ModalBody className="modal-body">
					<ul>
						{
							newPostTypeList.map((postType) => (
								<li key={postType.attributes.id}>
									<CustomInput
										type="checkbox"
										id={postType.attributes.id}
										checked={`${postTypeList[postType.attributes.id] ? 'checked' : ''}`}
										onChange={() => (this.handleTypeToggle(postType.attributes.id))}
									/>
									<Label for={postType.attributes.id}>
										{postType.attributes.name}
									</Label>
								</li>
							))
						}
                        <li>
							<CustomInput
                                type="checkbox"
								id="other"
								checked={isOtherTypeChecked ? 'checked' : ''}
								onChange={() => (this.handleOtherTypeToggle())}
							/>
							<Label for={'other'}>Other</Label>
							{
                                isOtherTypeChecked &&
									<Input
										type="text"
										name="other-text"
										id="other-text"
										maxLength="15"
										className="other-type-text"
										placeholder="Other type"
										{ ...otherTextProps }
										onChange={(e) => (this.handleOtherTypeTextChange(e))}
									/>
							}
						</li>
					</ul>
					<div>
						<h3 className="title-post-title">Post Title:</h3>
						<ul>
							<li>
								<CustomInput
                                    type="checkbox"
                                    id="auto-post-title"
                                    checked={isAutoPostTitle ? 'checked' : ''}
									onChange={() => (this.handleIsAutoPostTitleToggle())}
								/>
                                <Label for={'auto-post-title'}>
									Auto: {titlePlaceHolder}
								</Label>
							</li>
							<li>
								<CustomInput
                                    type="checkbox"
                                    id="manual-post-title"
                                    checked={isManualPostTitle ? 'checked' : ''}
									onChange={() => (this.handleIsManualPostTitleToggle())}
								/>
                                <Label for={'auto-post-title'}>
                                    Manual:
                                </Label>
                                <Input
                                    type="text"
                                    name="title"
                                    id="post-title"
                                    placeholder="Post title"
									maxLength="100"
									{ ...postTitleProps }
									disabled={!isManualPostTitle}
                                    onChange={(e) => (this.handlePostTitleChange(e))}
                                />
							</li>
						</ul>
					</div>

                    <h3 className="title-post-title">Post Content:</h3>
					<div className="post-note-container">
					<span className="post-note-text">We recommend that you not solicit funds or publicize a fundraise in a post,
					 as doing so could limit your ability to effectively raise funds under state and federal securities laws. 
					 Please contact your counsel if you have questions on legal issues surrounding a raise.</span>
					 </div>
					<StreamApp
						apiKey={apiKey}
						appId={appId}
						token={token}
					>
						<StatusUpdateForm
							modifyActivityData={(data) => this.modifyActivityData(data)}
							Header={
								<div style={{ padding: 0, margin: 0 }} />
							}
							onSuccess={this.props.toggle}
						/>
					</StreamApp>

					<div className="title-button-container">
                        <h3 className="title-post-title">Relevant sector(s): {sectorsTitle}</h3>
                        <Button
							color="primary"
							onClick={() => (this.toggleSectorSection())}
						>
							{isSectorsSectionOpened ? '-' : '+'}
						</Button>
					</div>
					<div className="post-note-container">
						<span className="post-note-text">Note: {sectorsNote}</span>
					</div>
					<div>
						<Collapse isOpen={isSectorsSectionOpened}>
                            <YourSectorForm
                                setFieldsMissing={this.props.setFieldsMissing}
                                isNewPostModal={true}
                                setSectorsToNewPostModal={this.onSetSectors}
								sectorLimit={false}
                            />
						</Collapse>
					</div>
				</ModalBody>
			</Modal>
		);
	}
}

export default NewPostModal;
