import React from "react";
import _ from 'lodash';
import { Nav, NavItem, NavLink, Navbar, NavbarBrand } from "reactstrap";
import { NavLink as RouterNavLink, Redirect, withRouter } from 'react-router-dom'
import { HeaderRoutes } from '../../../constants/headerRoutes'
import { AdminHeaderRoutes } from '../../../constants/adminHeaderRoutes'
import logo from "../../../commons/images/logo.svg";
import profilePic from "../../../commons/images/profile_pic.svg";
import BellIcon from "../../../commons/images/bell-icon.png";
import ConfirmLogoutModal from "../confirmLogoutModal/ConfirmLogoutModal";

class DesktopHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showExtraMenuDropdown: false,
            isConfirmLogoutModalOpened: false,
        };

        this.confirmLogoutModalToggle = this.confirmLogoutModalToggle.bind(this);
        this.handleConfirmLogout = this.handleConfirmLogout.bind(this);
    }


    componentDidMount(){
        const usertype = localStorage.getItem('user_type')
        if(usertype==='alumni' || usertype==='student' || usertype === 'advisor'){
            this.props.getUserData()
        }
        else{
            this.props.getAdminNotifications();
        }
    }

    static getDerivedStateFromProps(props, state){
        const { tcClient, msgFromIdentity, subscribedChannels } = props;

        if(tcClient && subscribedChannels && subscribedChannels.length===0){
            props.getUserSubscribedChannels(msgFromIdentity);
        }
        return {}
    }

    setActive = (value) => {
        this.props.setActiveScreen(value, this.props.profilePicBase64Copy);
        if(value==='messages'){
            this.props.clearNewMessageFromCount();
        }
    }

    handleShowLogouButton = () => {
        this.setState(prevState => ({
            showExtraMenuDropdown: !prevState.showExtraMenuDropdown
        }))
    }

    handleLogout = () => {
        localStorage.clear();
        this.props.setLogout();
        this.handleShowLogouButton();
	}
	
	handleFaq = () => {
		this.props.history.push('/faq')
		this.setActive('faq')
	}

    handleAboutUs = () => {
        this.props.history.push('/about')
		this.setActive('about')
	}

    handleUserProfile = () => {
        this.props.history.push('/userProfile')
		this.setActive('profile')
    }

    toggle = (tab) => {
        if(tab==='pendingAlumni'){
            this.props.getPendingUserData('pending', 'alumni', this.props.currentPage);
            this.props.setPendingUserType('alumni')
            this.props.setCurrentPage && this.props.setCurrentPage(1);

        }
        else if(tab==='pendingStudents'){
            this.props.getPendingUserData('pending', 'student', this.props.currentPage);
            this.props.setPendingUserType('student')
            this.props.setCurrentPage && this.props.setCurrentPage(1);
        }
        else if(tab==='pendingAdvisors'){
            this.props.getPendingUserData('pending', 'advisor', this.props.currentPage);
            this.props.setPendingUserType('advisor')
            this.props.setCurrentPage && this.props.setCurrentPage(1);
        }
    }

    confirmLogoutModalToggle() {
        this.setState({
            isConfirmLogoutModal: !this.state.isConfirmLogoutModal
        });
    }

    handleConfirmLogout() {
        this.handleLogout();
    }

    render() {
        const { activeScreen, userData, isTokenValid, newMessageFromCount  } = this.props;
        const { showExtraMenuDropdown } = this.state;
        const usertype = localStorage.getItem('user_type');
        const adminNotificationCount = _.get(this.props.adminNotificationList, '[0].attributes.total_unread', 0);

        return (
            <div className="desktop-menu">
                <Navbar className="custom-container-wrapper">
                    <NavbarBrand
                        className="logo-wrapper">
						<img src={logo} alt="no_image"/>
                    </NavbarBrand>
                    <Nav tabs>
                        {
                            HeaderRoutes && (usertype==='alumni' || usertype==='student' || usertype==='advisor') ?
                            HeaderRoutes.map((screen, i) => (
                                <NavItem key={i}>
                                    <NavLink
                                        to={screen.toRoute}
                                        tag={RouterNavLink}
                                        onClick={() => this.setActive(`${screen.activeScreenVal}`)}
                                        className={`${ activeScreen===`${screen.activeScreenVal}` ? 'active' : ''}`}
                                    >
                                        {`${screen.label} ${screen.label === 'Messages' && newMessageFromCount>0 ? `(${newMessageFromCount})` : ''}`}
                                    </NavLink>
                                </NavItem>
                            ))
                            : (
                                AdminHeaderRoutes.map((screen, index) => (
                                    <NavItem key={index}>
                                        {
                                            !screen.icon ? (
                                                <NavLink
                                                    to={`/admin${screen.toRoute}`}
                                                    tag={RouterNavLink}
                                                    onClick={() => this.toggle(screen.activeScreenVal)}
                                                >
                                                    {screen.label}
                                                </NavLink>
                                            ) :
                                            (
                                                <NavLink
                                                    to={`/admin${screen.toRoute}`}
                                                    tag={RouterNavLink}
                                                    onClick={() => this.toggle(screen.activeScreenVal)}
                                                    className="link-notifications"
                                                >
                                                    <div>
                                                        <img src={BellIcon} alt="Bell Icon" /> 
                                                            {
                                                                adminNotificationCount !== 0 &&
                                                                <span>{adminNotificationCount}</span>
                                                            }
                                                    </div>
                                                </NavLink>
                                            )
                                        }
                                    </NavItem>
                                ))
                            )
                        }
                        {
                            JSON.parse(localStorage.getItem('isAdmin')) ?
                            <button onClick={this.confirmLogoutModalToggle} className="btn-logout-admin">Logout</button>
                            :
                            <NavItem className="profile-dropdown-link">
                                <div className="profile-image-desk">
                                {
                                    userData.attributes && <img
                                        // src={userData.attributes['image-url'] ? userData.attributes['image-url'] : profilePic}
                                        src={(this.props.profilePicUrl) ? typeof this.props.profilePicUrl === "object" ? this.props.profilePicBase64 : this.props.profilePicUrl  : profilePic}
                                        alt="no_image"
                                        onClick={this.handleShowLogouButton}
                                    />
                                }
                                </div>
                                {
                                    showExtraMenuDropdown &&
                                    <ul className="profile-dropdown">
                                        <li onClick={this.handleUserProfile}>Profile / Settings</li>
                                        <li onClick={this.handleAboutUs}>Who&apos;s it for</li>
                                        <li onClick={this.handleFaq}>Who we are</li>
                                        <li onClick={this.confirmLogoutModalToggle}>Logout</li>
                                    </ul>
                                }
                            </NavItem>
                        }
                    </Nav>
                </Navbar>
                {
                    (!isTokenValid ? <Redirect to="/" /> : null)
                }
                <ConfirmLogoutModal
                    isOpen={this.state.isConfirmLogoutModal}
                    toggle={this.confirmLogoutModalToggle}
                    onConfirmLogout={this.handleConfirmLogout}
                />
            </div>
        );
    }
}

export default withRouter(DesktopHeader)