import React, { Component } from "react";
import {Label} from "reactstrap";
import Selector from '../../components/selector/Selector';

class ExistingUserFilters extends Component {
    componentDidMount = () => {
        this.props.getUniversityList();
    }

    clearFilters = () => {
        this.props.clearFilteredExistingUserList();
        this.props.setCurrentPage(1);
    }

    render() {
        const { universityList, getUniversityListPrediction, functionToBeUsed, setColOrUniv, colOrUniv } = this.props;
        return(
            <div className="filters-wrapper filter-in-admin">
                <div className="filter-title">
                    <span className="filter-head">Filters</span>
                </div>
                <div className="filter-options-container">
                    <div className="filter-option-section">
                        <div className="form-group">
                            <div className="filter-label-section">
                                <Label>Program Name</Label>
                                <span className="btn-clear-filter" onClick={this.clearFilters}>Clear Filter</span>
                            </div>
                            <Selector
                                selectorFor={'ExistingUserFilters'}
                                options={universityList}
                                getUniversityListPrediction={getUniversityListPrediction}
                                functionToBeUsed={functionToBeUsed}
                                setColOrUniv={setColOrUniv}
                                colOrUniv={colOrUniv}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ExistingUserFilters;
