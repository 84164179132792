import React, { Component } from "react";
import { Link } from "react-router-dom";
import BackIcon from "../../commons/images/back-icon.svg";
import DesktopHeader from "../../containers/homePage/DesktopHeader";
import MobileMenu from "../../containers/homePage/MobileMenu";

class About extends Component {
    state = {};
    render() {
        return (
            <React.Fragment>
                <DesktopHeader />
                <div className="about-wrapper">
                    <div className="edit-details-container about-us-subheader">
                        <span>Who&apos;s it for</span>
                    </div>
                    <div className="custom-profile-container aboutus-container">
						<p className="about-text">
							GradShack provides actionable interpersonal connections for people excited about new
							ventures. School communities (both graduate and undergraduate programs) are the hubs
							around which our introductions are suggested, though users are free to connect
							across schools as well. GradShack is open to both alumni and students, and
							“intergenerational collaboration” is strongly encouraged.
						</p>
						<p className="about-text">
							Use cases for joining GradShack are various and open-ended. A few examples:
						</p>
						<p className="about-text">
							- You have a great business idea but no time to pursue it. You use GradShack to find
							a talented, aspiring entrepreneur who hasn’t landed on their own idea yet and is
							excited about moving yours forward.
						</p>
						<p className="about-text">
							- You want to start a company but haven’t found that perfect idea and/or cofounder
							yet. You use GradShack to find a partner.
						</p>
						<p className="about-text">
							- You’re running an early-stage venture and use GradShack to find a co-founder,
							early hire, advisor, or beta tester.
						</p>
						<p className="about-text">
							- You’re exploring start-up ideas or building a company in a certain sector and use
							GradShack to find people with a stated interest in supporting innovation in that
							sector.
						</p>
						<p className="about-text">
							- You are an advisor and use GradShack to learn about exciting projects
							that need your help.
						</p>
						<p className="about-text">
							- You’d like to have a super-basic MVP to help test your business concept and/or
							recruit a team, but you don’t have the technical chops to build it yourself, and
							you’re not ready to give away half the company. You use GradShack to find a reliable
							technical partner who can quickly and inexpensively build you an “MVP-lite.”
						</p>
                    </div>
                </div>
                <MobileMenu />
            </React.Fragment>
        );
    }
}

export default About;
