import { connect } from 'react-redux';
import { createNamespacer } from '../utils/reducers';
import { LOGIN } from '../constants/namespacer'
import { getUserData } from '../api/commonApi/getUserData'
import RedirectAfterLogin from '../components/redirectAfterLogin/RedirectAfterLogin'

const loginNamespacer = createNamespacer(LOGIN);

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.redirectAfterLogin.isAuthenticated,
        authCode: state.redirectAfterLogin.auth_code,
        userId: state.redirectAfterLogin.userId,
        userData: state.redirectAfterLogin.userData,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return{
        setAuthCode: (value) => {
            dispatch({
                type: loginNamespacer('SET_AUTH_CODE'),
                authCode: value,
            })
        },
        setUserId: (value) => {
            dispatch({
                type: loginNamespacer('SET_USER_ID'),
                userId: value,
            })
        },
        getUserData: async() => {
            try{
                let response  = await getUserData();
                if(response.status===200){
                    dispatch({
                        type: loginNamespacer('SET_USER_DATA'),
                        userData: response.data.data
                    })
                    dispatch({
                        type: loginNamespacer('SET_IS_AUTHENTICATED'),
                        isTokenValid: true
                    })
                } 
                else {
                    ownProps.history.replace('/')
                }
            }
            catch(error){
                console.log(error);
                ownProps.history.replace('/')
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RedirectAfterLogin);

