import axios from 'axios'
import {baseUrl} from '../constants/config.js'
import * as Sentry from '@sentry/browser'
import store from '../store';
import { LOGIN } from '../constants/namespacer';
import { createNamespacer } from '../utils/reducers'

const loginNamespacer = createNamespacer(LOGIN);

const axiosInstanceHandler = () => {
    const token = localStorage.getItem('auth_code')
    
    const axiosInstance = axios.create({
        baseURL: baseUrl,
        headers: { 'Authorization': 'Bearer ' + token },
    })

    axiosInstance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        Sentry.captureException(error);
        if(error.response.status===401){
            localStorage.clear();
            store.dispatch({
                type: loginNamespacer('SET_IS_AUTHENTICATED'),
                isTokenValid: false
            })
        }
        return Promise.reject(error);
    });

    return axiosInstance
}

export default axiosInstanceHandler;