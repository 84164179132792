import { connect } from 'react-redux';
import { getUniversityList } from '../../api/userForms/YourInfoForm/getUniversityList'
import { getWorkLocationList } from '../../api/userForms/YourInfoForm/getWorkLocationList'
import { getUniversityListPrediction } from '../../api/userForms/YourInfoForm/getUniversityListPrediction'
import { getLocationListPrediction } from '../../api/userForms/YourInfoForm/getLocationListPrediction'
import { createNamespacer } from '../../utils/reducers';
import { USERFORMDATA } from '../../constants/namespacer';
import EditMyInfo from '../../components/editProfile/editMyInfo/EditMyInfo';

const userFormDataNamespacer = createNamespacer(USERFORMDATA);

const maptStateToProps = (state) => {
    return{
        profilePicUrl: state.yourInfoForm.profilePicUrl,
        name: state.yourInfoForm.name,
        lastName: state.yourInfoForm.lastName,
        email: state.yourInfoForm.email,
        linkedinProfileUrl: state.yourInfoForm.linkedinProfileUrl,
        mbaProgram: state.yourInfoForm.mbaProgram,
        colOrUniv: state.yourInfoForm.colOrUniv,
        graduationYear: state.yourInfoForm.graduationYear,
        currentWorkLocation: state.yourInfoForm.currentWorkLocation,
        isTokenValid: state.redirectAfterLogin.isTokenValid,
        universityList: state.yourInfoForm.universityList,
        workLocationList: state.yourInfoForm.workLocationList,
        isEmailNotificationOn: state.yourInfoForm.isEmailNotificationOn
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setProfilePic: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_PROFILE_PIC_URL'),
                profilePicUrl: value,
            })
        },
        setName: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_NAME_VALUE'),
                name: value,
            })
        },
        setLastName: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_LAST_NAME_VALUE'),
                lastName: value,
            })
        },
        setEmail: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_EMAIL_VALUE'),
                email: value,
            })
        },
        setLinkedinProfileUrl: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_LINKEDIN_PROFILE_URL_VALUE'),
                linkedinProfileUrl: value,
            })
        },
        setMbaProgram: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_MBA_PROGRAM_VALUE'),
                mbaProgram: value,
            })
        },
        setColOrUniv: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_COL_OR_UNIV_VALUE'),
                colOrUniv: value,
            })
        },
        setGraduationYear: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_GRADUATION_YEAR_VALUE'),
                graduationYear: value,
            })
        },
        setCurrentWorkLocation: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_CURRENT_WORK_LOCATION_VALUE'),
                currentWorkLocation: value,
            })
        },
        getUniversityList: async() => {
            try{
                let response = await getUniversityList();
                if(response.status===200){
                    dispatch({
                        type: userFormDataNamespacer('SET_UNIVERSITY_LIST'),
                        payload: response.data.data
                    })
                } 
            }
            catch(error){
                console.log(error)   
            }
        },
        getWorkLocationList: async() => {
            try{
                let response = await getWorkLocationList();
                if(response.status===200){
                    dispatch({
                        type: userFormDataNamespacer('SET_WORK_LOCATION_LIST'),
                        payload: response.data.data
                    })
                }
            }
            catch(error){
                console.log(error)
            }
        },
        getUniversityListPrediction: async(param) => {
            try{
                let response = await getUniversityListPrediction(param);
                if(response.status===200){
                    dispatch({
                        type: userFormDataNamespacer('SET_UNIVERSITY_LIST'),
                        payload: response.data.data
                    })
                }
            }
            catch(error){
                console.log(error)
            }
        },
        getLocationListPrediction: async(param) => {
            try{
                let response = await getLocationListPrediction(param);
                if(response.status===200){
                    dispatch({
                        type: userFormDataNamespacer('SET_WORK_LOCATION_LIST'),
                        payload: response.data.data
                    })
                }
            }
            catch(error){
                console.log(error)
            }
        },
        setIsEmailNotificationOn: (value) => {
            dispatch({
                type: userFormDataNamespacer('SET_EMAIL_NOTIFICATION_ON_VALUE'),
                isEmailNotificationOn: value
            })
        }
        
    }
}

export default connect(maptStateToProps, mapDispatchToProps)(EditMyInfo);