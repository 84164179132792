import React, { Component } from "react";
import { StreamApp, FlatFeed, NewActivitiesNotification } from 'react-activity-feed';
import NewSignUpUserCard from '../../containers/NewSignUpUserCard';

class UserNewsFeed extends Component {
    componentDidMount() {
        this.props.checkInitialUserNewsFeedSettings();
    }

    render() {
        const {
            getStreamApiKey,
            getStreamAppId,
            getStreamApiToken,
            getStreamFeedName,
            userId,
        } = this.props;
		const feedPrefix = getStreamFeedName.split('_')[0]
        const feedName = userId ? feedPrefix + '_' + userId : getStreamFeedName;

        return (
            <div className={`${userId ? 'newsfeed-wrapper' : ''}`}>
                <div className="newsfeed-wrapper-main">
                    <div className="newsfeed-wrapper-sidebar">
                    </div>
                    {
                        getStreamApiKey && getStreamAppId && getStreamApiToken &&
                        <StreamApp
                            apiKey={getStreamApiKey}
                            appId={getStreamAppId}
                            token={getStreamApiToken}
                        >
                            <div className="newsfeed-wrapper-main-content">
                                <div className="container">
                                    <div className="row">
                                        <FlatFeed
                                            notify
                                            feedGroup="user"
                                            userId={feedName}
                                            Activity={(props) => (
                                                <div className="col-lg-6 mb-0 mb-lg-3">
                                                    <NewSignUpUserCard
                                                        activityProps={props}
                                                        getStreamApiKey={getStreamApiKey}
                                                        getStreamAppId={getStreamAppId}
                                                        getStreamApiToken={getStreamApiToken}
                                                    />
                                                </div>
                                            )
                                            }
                                            Notifier={(props) => {
                                                const {labelFunction: labelFunction, ...otherProps} = props;
                                                return (
                                                    <NewActivitiesNotification
                                                        labelFunction={(p)=> p.addCount !== 0 ? `You have new posts` : null}
                                                        { ...otherProps }
                                                    />
                                                )}
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </StreamApp>
                    }
                </div>
            </div>
        )
    }
}

export default UserNewsFeed;
