import React, { Component } from "react";
import { Button } from "reactstrap";
import { Redirect } from 'react-router-dom';
import UserFormModal from '../userFormButtons/UserFormModal'

class UserFormButtons extends Component {

    // state = {
    //     redirectTo: null,
    //     isDisabled: true,
    // }
    constructor(props) {
		super(props);
		this.state = {
			redirectTo: null,
            isDisabled: true,
            userFormModal: false,
		};
		this.userFormModalToggle = this.userFormModalToggle.bind(this);
    }
    userFormModalToggle() {
        this.setState({
			userFormModal: !this.state.userFormModal
		});
    }

    static getDerivedStateFromProps(props, state) {
        const { currentFormTab } = props;

        if (currentFormTab === '1') {
            const {
                name,
                email,
                mbaProgram,
                colOrUniv,
                graduationYear,
                currentWorkLocation,
                lastName,
                privacyCheckboxIsChecked
            } = props;

            let reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
            return {
                isDisabled: !(
                    name &&
                    lastName &&
                    email &&
                    reg.test(email) &&
                    colOrUniv &&
                    mbaProgram &&
                    graduationYear &&
                    currentWorkLocation &&
                    privacyCheckboxIsChecked
                )
            }
        }
        else if (currentFormTab === '2') {
            const { sectors } = props;
            return {
                isDisabled: !(sectors.length > 0 && sectors.length < 4)
            }
        }
        else if (currentFormTab === '3') {
            const { userType } = props;
            return {
                isDisabled: !userType
            }
        }
        else if (currentFormTab === '4') {
            const { concept, venture, needs, userType, investorType } = props;
            let isDisabled = false;

            // if (needs.includes('advisor')) {
            //     isDisabled = !investorType;
            // }

            return {
                isDisabled: userType === 'alumni' && !(concept && venture && needs.length !== 0) || isDisabled
            }
        }
    }

    handleYourInfoPut = () => {
        const {
            name,
            lastName,
            email,
            linkedinProfileUrl,
            mbaProgram,
            colOrUniv,
            graduationYear,
            currentWorkLocation,
            profilePicUrl
        } = this.props;

        let bodyFormData = new FormData();
        bodyFormData.append('user[first_name]', name );
        bodyFormData.append('user[last_name]', lastName );
        bodyFormData.append('user[email]', email );
        bodyFormData.append('user[profile_url]', linkedinProfileUrl);
        bodyFormData.append('user[grad_year]', graduationYear );
        bodyFormData.append('user[mba_program]', mbaProgram);
        bodyFormData.append('user[university_id]', typeof(colOrUniv)==='number' ? colOrUniv : colOrUniv.id);
        bodyFormData.append('user[location_id]', typeof(currentWorkLocation)==='number' ? currentWorkLocation : currentWorkLocation.id);
        bodyFormData.append('user[email_notification?]', true);
        bodyFormData.append('user[terms_accepted]',true);
        if (profilePicUrl) {
            bodyFormData.append('user[photo]', profilePicUrl);
        }

        this.props.saveUserData(bodyFormData);
    }

    handleInterestsPut = () => {
        const { sectors } = this.props;

        let bodyFormData = new FormData();

        for(let i = 0; i < sectors.length; i++) {
            bodyFormData.append(`user[user_interests_attributes][${i}][interest_id]`, sectors[i]);
        }
        bodyFormData.append('user[status]','approved');

        this.props.saveUserData(bodyFormData);
    }

    handleConceptsPut = () => {
        const { concept, venture, needs, userType, feasibilityStudyAmount, investorType, additionalDetails } = this.props;
        const bodyFormData = new FormData();

        if (userType === 'alumni') {
            bodyFormData.append('user[alumni_detail_attributes][concept]', concept);
            bodyFormData.append('user[alumni_detail_attributes][state]', venture);
            if (needs.constructor === Array) {
                for (let i = 0; i < needs.length; i++) {
                    const needToday = needs[i];
                    if (needToday !== 'feasibility_study') {
                        bodyFormData.append('user[alumni_detail_attributes][need_today][]', needToday);
                    } else if (needToday === 'feasibility_study') {
                        bodyFormData.append('user[alumni_detail_attributes][need_today][]', needToday + '|' + feasibilityStudyAmount);
                    // } else if (needToday === 'advisor') {
                    //     bodyFormData.append('user[alumni_detail_attributes][need_today][]', needToday);
                    }
                }
            }
            else {
                bodyFormData.append('user[alumni_detail_attributes][need_today][]', needs);
            }

            if (additionalDetails) {
                bodyFormData.append(
                    'user[interest_desc]',
                    additionalDetails
                );
            }
        }

        this.props.saveUserData(bodyFormData);
    }

    handleAdditionalDetailsPut = () => {
        const { additionalDetails } = this.props;
        const bodyFormData = new FormData();

        bodyFormData.append('user[interest_desc]', additionalDetails);

        this.props.saveUserData(bodyFormData);
    }

    handleUserTypePut = () => {
        const { userType, isInvestor } = this.props;
        const bodyFormData = new FormData();

        if (userType === 'alumni' || userType === 'student' || userType === 'advisor') {
            bodyFormData.append('user[user_type]', userType);
        }
        bodyFormData.append('user[is_investor]', isInvestor);

        this.props.saveUserData(bodyFormData);
    }

    handlePrevious = (params) => {
        const activeTab = this.props.currentFormTab;

        switch (activeTab) {
            case '1':
                this.routeChange('/userSignUp');
                this.props.clearFormData();
                break;
            case '2':
            case '3':
            case '4':
            default:
                this.props.setCurrentFormTab((parseInt(activeTab) - 1) + '');
                break;
        }
    }

    handleNext = (params) => {
        const activeTab = this.props.currentFormTab;

        if (activeTab === '1') {
            const {
                name,
                lastName,
                email,
                linkedinProfileUrl,
                mbaProgram,
                colOrUniv,
                graduationYear,
                currentWorkLocation
            } = this.props;

            let isValid = this.validate(
                name,
                email,
                mbaProgram,
                colOrUniv,
                graduationYear,
                currentWorkLocation,
                lastName,
                linkedinProfileUrl
            );

            if (isValid) {
                this.props.setCurrentFormTab((parseInt(activeTab) + 1) + '');
                this.handleYourInfoPut();
            }
        }
        else if (activeTab === '2') {
            const { sectors } = this.props;

            let isValid = this.validate(sectors);
            if (isValid) {
                this.props.setCurrentFormTab((parseInt(activeTab) + 1) + '');
                this.handleInterestsPut();
            }
        }
        else if (activeTab === '3') {
            const { userType } = this.props;
            let isValid = this.validate(userType);
            if (isValid) {
                this.props.setCurrentFormTab((parseInt(activeTab) + 1) + '');
                this.handleUserTypePut();
            }
        }
        else if (activeTab === '4') {
            const { userType } = this.props;
            let isValid = false;

            if (userType === 'alumni') {
                const { concept, venture, needs, investorType } = this.props;

                isValid = this.validate(concept, venture, needs);
                this.handleConceptsPut();
            }
            else if (userType === 'student' || userType === 'advisor') {
                isValid = true;
                this.handleAdditionalDetailsPut();
            }

            if (isValid) {
                this.routeChange('/thankYou');
            }
        }
    }

    routeChange = (path) => {
        this.setState({redirectTo: path});
    }

    validate = (...params) => {
        let activeTab = this.props.currentFormTab;

        if (activeTab === '1') {
            const name = params[0];
            const email = params[1];
            const mbaProgram = params[2];
            const colOrUniv = params[3];
            const graduationYear = params[4];
            const currentWorkLocation = params[5];
            const lastName = params[6];

            return name && lastName && email && mbaProgram && colOrUniv && graduationYear && currentWorkLocation;
        }
        else if (activeTab === '2') {
            const sectors = params[0];

            if (sectors.length === 0) {
                return false;
            }
            else if (sectors.length > 3) {
                return false;
            }
            return true;
        }
        else if (activeTab === '3') {
            const userType = params[0];

            if (!userType) {
                return false;
            }

            return true;
        }
        else if (activeTab === '4') {
            const concept = params[0];
            const venture = params[1];
            const needs = params[2];
            const investorType = params[3];

            // if (needs.includes('advisor')) {
            //     return concept && venture && needs.length !== 0 && investorType;
            // }

            return concept && venture && needs.length !== 0;
        }
    }
    render() {
        const { currentFormTab } = this.props;
        const { redirectTo, isDisabled } = this.state;
        return (
            <React.Fragment>
            <div className="form-btn-wrapper">
                <Button className={`normal-btn previous-button`} onClick={this.handlePrevious}>
                    Previous
                </Button>
                <Button className="next-button" onClick={this.handleNext} disabled={isDisabled}>
                    {currentFormTab === '4' ? 'Finish' : 'Next'}
                </Button>
                {redirectTo ? <Redirect to={redirectTo} /> : null}
            </div>
            <UserFormModal isOpen={this.state.userFormModal} toggle={this.userFormModalToggle} />
            </React.Fragment>
        );
    }
}

export default UserFormButtons;
