import React, { Component } from 'react';
import logo from '../../commons/images/logo.svg';
import HeaderBeforeLogin from '../headerBeforeLogin/headerBeforeLogin';
import { landingPageUrl } from '../../constants/config';
import PrivacyModal from '../staticPages/privacyPolicy';
import TermsModal from '../staticPages/terms';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { Button, Form, FormGroup } from 'reactstrap';

export default class LandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			authURL: landingPageUrl,
			privacyModal: false,
			termsModal: false
		};

		this.privacyModalToggle = this.privacyModalToggle.bind(this);
		this.termsModalToggle = this.termsModalToggle.bind(this);
	}
	
	privacyModalToggle() {
		this.setState({
			privacyModal: !this.state.privacyModal
		});
	}

	termsModalToggle() {
		this.setState({
			termsModal: !this.state.termsModal
		});
	}

	handleUserEmail = (event) => {
		this.props.setUserEmail(event.target.value);
	};

	handleUserPassword = (event) => {
		this.props.setUserPassword(event.target.value);
	};

	handleUserLogin = (event) => {
		const { userEmail, userPassword } = this.props;
		if (userEmail && userPassword) {
			this.props.userLogin(userEmail, userPassword);
		} else {
			alert('Please enter Email and Password');
		}
	};

	render() {
		const { authURL } = this.state;
		let { isTokenValid } = this.props;
		return (
			<React.Fragment>
				<div className="login-wrapper d-flex flex-column flex-md-row">
					<div className="login-banner-container" />
					<div className="login-right-container d-flex">
						<HeaderBeforeLogin />
						<div className="login-inner-container ">
							<img src={logo} alt="Gradshack" />
							<h4 className="title">Startups, ideas and idea-seekers from your school.</h4>

							<Form className="form-container login-form">
								<FormGroup>
									<input
										type="email"
										placeholder="Email"
										className="form-control"
										onChange={this.handleUserEmail}
									/>
								</FormGroup>

								<FormGroup>
									<input
										type="password"
										placeholder="Password"
										className="form-control"
										onChange={this.handleUserPassword}
									/>
								</FormGroup>

								<Button onClick={this.handleUserLogin} className="landing-btn">
									Log in
								</Button>

								{isTokenValid ? <Redirect to="/homepage" /> : null}

								<div className="buttons-wrapper">
									<Link to="/userSignUp" className="landing-btn">
										Sign up
									</Link>

									<a href={authURL} className="landing-btn">
										Continue with LinkedIn
									</a>
								</div>
							</Form>
							<p className="privacy-link">
								By signing up you agree to GradShack’s{' '}
								<span>
									<button onClick={this.privacyModalToggle}>Privacy</button> <strong>&</strong> <button onClick={this.termsModalToggle}>Terms</button>
								</span>
							</p>
							<span className="comment-text">
								Comments? Questions? Email us at{' '}
								<a href="mailto:contact@gradshack.com">contact@gradshack.com</a>
							</span>
						</div>
					</div>
				</div>
				<PrivacyModal isOpen={this.state.privacyModal} toggle={this.privacyModalToggle} />
				<TermsModal isOpen={this.state.termsModal} toggle={this.termsModalToggle} />
			</React.Fragment>
		);
	}
}
