import React, { Component } from "react";
import { Table, FormGroup, Label, Input } from "reactstrap";
import _ from "lodash";

class EditNotificationsForm extends Component {
  state = {
    notificationOptions: [],
  };

  componentDidMount = () => {
  };

  componentDidUpdate(prevProps, prevState) {}

  inputCell = () => {
    return (
      <FormGroup check>
        <Label check>
          <Input type="radio" name="radio1" />
        </Label>
      </FormGroup>
    );
  };

  inputSlider = () => {
    return (
      <FormGroup className="email-notification-group">
        <div className="switch">
          <Input type="checkbox" />
          <span className="slider round"></span>
        </div>
      </FormGroup>
    );
  };

  inputRow = (label) => {
    return (
      <tr>
        <td>{label}</td>
        <td>{this.inputSlider()}</td>
        <td>{this.inputSlider()}</td>
      </tr>
    );
  };

  inputGroup = (values) => {
    return values.map((val, index) => this.inputRow(val));
  };

  render() {
    const { notificationOptions } = this.state;

    return (
      <div className="form-wrapper edit-form-wrapper">
        <div className="studentForm-head-block">
          <h6>Notification Preferences</h6>
        </div>
        <div className="form-container notification-form">
          <Table striped>
            <thead>
              <tr>
                <th></th>
                <th className="notification-header">Push</th>
                <th className="notification-header">Email Digest</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="3" className="notification-subtitle">
                  General
                </td>
              </tr>
              {this.inputGroup(["Comments", "Messages", "Site Announcements"])}
              <tr>
                <td colSpan="3" className="notification-subtitle">
                  Sectors of Interest
                </td>
              </tr>
              {this.inputGroup([
                "Agriculture",
                "Community Building",
                "Consumer",
                "Education",
                "Energy & Environment",
                "Finance",
                "Government & Military",
                "Healthcare & Fitness",
                "Hospitality",
                "Human Resources & Recruiting",
                "Industrials & Transportation",
                "International",
                "Law",
                "Media, Art & Entertainment",
                "Real Estate",
                "Sales, Marketing & Advertising",
                "Security",
                "Social Impact",
                "Technology",
                "Telecom",
              ])}
              <tr>
                <td colSpan="3" className="notification-subtitle">
                  Types of Posts
                </td>
              </tr>
              {this.inputGroup([
                "Full-time Role",
                "Feasibility Study",
                "Advising",
                "Beta Test",
                "MVP",
                "Other",
              ])}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default EditNotificationsForm;
