import { connect } from 'react-redux'
import { createNamespacer } from '../utils/reducers'
import { LOGIN, USERLOGIN } from '../constants/namespacer'
import { loginUser } from '../api/userForms/loginUser'
import LandingPage from '../components/landingPage/LandingPage'

const loginNamespacer = createNamespacer(LOGIN);
const userLoginNamespacer = createNamespacer(USERLOGIN);

const mapStateToProps = (state) => {
    return {
        userEmail: state.loginUser.userEmail,
        userPassword: state.loginUser.userPassword,
        isTokenValid: state.redirectAfterLogin.isTokenValid
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserEmail: (value) => {
            dispatch({
                type: userLoginNamespacer('SET_USER_EMAIL'),
                userEmail: value
            })
        },
        setUserPassword: (value) => {
            dispatch({
                type: userLoginNamespacer('SET_USER_PASSWORD'),
                userPassword: value
            })
        },
        userLogin: async (userEmail, userPassword) => {
            try {
                let bodyFormData = new FormData();
                bodyFormData.append('user[email]', userEmail);
                bodyFormData.append('user[password]', userPassword);

                let response = await loginUser(bodyFormData);

                if (response.status === 200) {
                    localStorage.setItem('id', response.data.user.id);
                    localStorage.setItem('auth_code', response.data.auth_token);
                    localStorage.setItem('isLogin', true);
                    localStorage.setItem('isAdmin', false);
                    localStorage.setItem('getStreamApiKey', response.data.user.newsfeed.api_key);
                    localStorage.setItem('getStreamAppId', response.data.user.newsfeed.app_id);
                    localStorage.setItem('getStreamApiToken', response.data.user.newsfeed.newsfeed_token);
                    localStorage.setItem('getStreamFeedName', response.data.user.newsfeed.feed_name);
                    localStorage.setItem('getStreamNotificationFeedName', response.data.user.newsfeed.notification_feed_name);

                    dispatch({
                        type: loginNamespacer('SET_IS_AUTHENTICATED'),
                        isTokenValid: 'true'
                    });
                }
            } catch (error) {
              console.error(error);
              if (error.response && error.response.data) {
                const errorMessages = error.response.data.error;
                alert(errorMessages)
              }
            }
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage)
