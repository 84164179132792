import { connect } from 'react-redux';
import { createNamespacer } from '../../utils/reducers'
import { HOMEPAGE, TWILIOCHAT } from '../../constants/namespacer'
import MobileMenu from '../../components/homePage/mobileMenu/MobileMenu'

const homePageNamespacer = createNamespacer(HOMEPAGE);
const twilioChatNamespacer = createNamespacer(TWILIOCHAT);


const mapStateToProps = (state) => {
    return {
       activeScreen: state.desktopHeader.activeScreen,
       newNotification: state.desktopHeader.newNotification,
       profilePicUrl: state.yourInfoForm.profilePicUrl,
        getStreamApiKey: state.homePage.getStreamApiKey,
        getStreamAppId: state.homePage.getStreamAppId,
        getStreamApiToken: state.homePage.getStreamApiToken,
        getStreamFeedName: state.homePage.getStreamFeedName,
        getStreamNotificationFeedName: state.homePage.getStreamNotificationFeedName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setActiveScreen: (value) => {
            dispatch({
                type: homePageNamespacer('SET_ACTIVE_SCREEN_VALUE'),
                activeScreen: value
            })
        },
        setNewNotification: () => {
            dispatch({
                type: homePageNamespacer('SET_NEW_NOTIFICATION_VALUE'),
                newNotification: true,
            })
        },
        clearNewMessageFromCount: () => {
            dispatch({
                type: twilioChatNamespacer('SET_NEW_MSG_FROM_COUNT'),
                newMessageFromCount: 0,
            })
        },
        checkInitialHomePageSettings: async() => {
            const apiKey = localStorage.getItem('getStreamApiKey');
            const appId = localStorage.getItem('getStreamAppId');
            const token = localStorage.getItem('getStreamApiToken');
            const feedName = localStorage.getItem('getStreamFeedName');
            const notificationFeedName = localStorage.getItem('getStreamNotificationFeedName');

            dispatch({
                type: homePageNamespacer('SET_GETSTREAM_API_KEY'),
                getStreamApiKey: apiKey
            });
            dispatch({
                type: homePageNamespacer('SET_GETSTREAM_API_ID'),
                getStreamAppId: appId
            });
            dispatch({
                type: homePageNamespacer('SET_GETSTREAM_API_TOKEN'),
                getStreamApiToken: token
            });
            dispatch({
                type: homePageNamespacer('SET_GETSTREAM_FEED_NAME'),
                getStreamFeedName: feedName
            });
            dispatch({
                type: homePageNamespacer('SET_GETSTREAM_NOTIFICATION_FEED_NAME'),
                getStreamNotificationFeedName: notificationFeedName
            });
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);

