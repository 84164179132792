import React, { Component } from "react";
import { Button } from 'reactstrap';
import DesktopHeader from '../../containers/homePage/DesktopHeader';
import MobileMenu from '../../containers/homePage/MobileMenu';
import UserNewsFeed from '../../containers/UserNewsFeed';
import { Redirect } from 'react-router-dom';
import profilePic from "../../commons/images/profile_pic.svg";
import BlockingLoader from "../commonComponents/Loader";
import { needTodayList } from "../../constants/needTodayList";

class ProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectTo: null,
        };
    }

    handleEditProfile = () => {
        this.setState({ redirectTo: '/editProfile' })
    };

    render() {
        const {
            userType,
            profilePicUrl,
            name,
            lastName,
            email,
            linkedinProfileUrl,
            mbaProgram,
            colOrUniv,
            graduationYear,
            currentWorkLocation,
            concept,
            venture,
            needs,
            sectors,
            additionalDetails,
            profilePicBase64,
            userId,
        } = this.props;
        const { redirectTo } = this.state;

        let ventureUpperCase, needsToShow, feasibilityStudyAmount, investorType;

        if (userType === 'alumni') {
            ventureUpperCase = venture.split('_').join(' ');
            ventureUpperCase = `${ventureUpperCase.charAt(0).toUpperCase()}${ventureUpperCase.substring(1)}`;

            needsToShow = needs[0] ? needs.map(need => {
                const result = need.includes('|') ? null : need;
                return needTodayList.find(item => item && item.attributes && item.attributes.id === result);
            }) : [''];

            if (needs[0]) {
                const feasibilityStudyValue = needs.find(item => item.includes('|') && item.split('|')[0] === 'feasibility_study');
                if (feasibilityStudyValue) {
                    feasibilityStudyAmount = feasibilityStudyValue.split('|')[1];
                }

                // const investorTypeValue = needs.find(item => item.includes('|') && item.split('|')[0] === 'advisor');
                // if (investorTypeValue) {
                //     // investorType = investorTypeValue.split('|')[1];
                // 	investorType = true
                // }
            }
        }

        const stringColOrUniv = typeof(colOrUniv)==='string' ? colOrUniv : '';
        const stringCurrentWorkLocation = typeof(currentWorkLocation)==='string' ? currentWorkLocation : '';
        const labelSetcorOrInterest = (userType==='alumni')
            ? 'Sectors'
            : (userType==='student' || userType ==='advisor'
                    ? 'My Interests'
                    : ''
            );

        let universityName = stringColOrUniv;

        return (
            <React.Fragment>
                {this.props.profileLoading && <BlockingLoader/>}
                <>
                    <DesktopHeader/>
                    <div className="screen-height">
                        <div className="edit-details-container">
                            <span>Profile</span>
                            <Button
                                className="edit-btn-profile"
                                onClick={this.handleEditProfile}
                            >Edit Profile</Button>
                        </div>
                        <div className="profile-wrapper custom-profile-container custom-profile-container-edit-profile">
                            <div className="profile-basic-info">
                                <div className="profile-picture">
                                    <img
                                        src={profilePicBase64 ? profilePicBase64 : profilePic}
                                        alt="no_image"
                                        onError={(e)=>{e.target.onerror = null; e.target.src=profilePic}}
                                    />
                                </div>
                                <span className="profile-name">{name} {lastName}</span>
                                <div className="profile-address">
                                    <span>{universityName} {mbaProgram} {graduationYear}</span>
                                    <span>{stringCurrentWorkLocation}</span>
                                </div>
                                <span className="profile-email">{email}</span>
                                {
                                    linkedinProfileUrl ?
                                        <div className="user-contact-details">
                                            <a
                                                href={linkedinProfileUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="linked-link">LinkedIn Profile
                                            </a>
                                        </div> : ''
                                }
                            </div>
                            {
                                userType === 'alumni' &&
                                <div className="venture-details">
                                    <span className="venture-title">My Venture</span>
                                    <div className="venture-desc">
                                        <span>My Concept</span>
                                        <p>{concept}</p>
                                    </div>
                                    <div className="venture-desc">
                                        <span>This Venture is Currently</span>
                                        <p>{ventureUpperCase}</p>
                                    </div>
                                    <div className="venture-desc">
                                        <span>What I Need Today</span>
                                        {
                                            needsToShow.map((need, i) => (
                                                <p key={i}>
                                                    {
                                                        need && need.attributes && need.attributes.name
                                                            ? need.attributes.name
                                                            : ''
                                                    }
                                                </p>
                                            ))
                                        }
                                        {
                                            feasibilityStudyAmount &&
                                            <p>Feasibility Study: (${feasibilityStudyAmount})</p>
                                        }
                                        {
                                            investorType &&
                                            <p>Advisor</p>
                                        }
                                    </div>
                                </div>
                            }
                            <div className={`venture-details ${userType==='alumni' ? 'sector-details' : ''}`}>
                                <span className="venture-title">{labelSetcorOrInterest}</span>
                                <div className="venture-desc sector-desc">
                                    {
                                        sectors &&
                                        sectors.map((sector, i) => (
                                            <span key={i}>{sector.name}</span>
                                        ))
                                    }
                                </div>
                            </div>
                            {
                                (userType==='student' || userType === 'advisor') ?
                                    <div className="venture-details sector-details">
                                        <span className="venture-title">Additional Details</span>
                                        <div className="venture-desc sector-desc">
                                            <span>{additionalDetails}</span>
                                        </div>
                                    </div> : ''
                            }
                            {
                                <div className="venture-details notification-details">
                                    <span className="venture-title">Notification Preferences</span>
                                    <div className="venture-desc sector-desc">
                                        {
                                            sectors &&
                                            sectors.map((sector, i) => (
                                                <span key={i}>{sector.name}</span>
                                            ))
                                        }
                                        <span>MVP</span>
                                        <span>Full-time role</span>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            userId ?
                                <UserNewsFeed userId={userId} /> : ""
                        }
                    </div>
                    <MobileMenu />
                    { redirectTo ? <Redirect to={redirectTo} /> : null }
                </>
            </React.Fragment>

        );
    }
}

export default ProfilePage;
