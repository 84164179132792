import React, { Component } from "react";
import {Label} from 'reactstrap';

class EditSectorForm extends Component {
    state = {
        oldSectorSelected: [],
        sectorSelectedIds: [],
        filteredSectorList: [],
    };

    componentDidMount = () => {
        this.props.getSectorList();
        this.setState({
            oldSectorSelected: this.props.sectors,
            sectorSelectedIds: this.props.sectors.map(eachSector => `${eachSector.id}`),
        })
    }
   
    componentDidUpdate(prevProps, prevState){
        if(prevProps.sectorList !== this.props.sectorList) {
            let filteredSectorList = this.props.sectorList.length ? this.props.sectorList.map((sector) => {
                return {
                    ...sector,
                    checked: this.state.sectorSelectedIds.includes(sector.id) ? true : false
                }
            }) : []
            this.setState({
                filteredSectorList
            })
        }
    }

    handleSectors = (sectorId) => {
        const { filteredSectorList, sectorSelectedIds} = this.state;
        if((sectorSelectedIds.length===3) && !sectorSelectedIds.includes(sectorId) ){
            alert('Three sectors maximum');
            return;
        }

        let latestFilteredSectorList = filteredSectorList.map((sector) => {
            if(sector.id === sectorId) {
                return {
                    ...sector,
                    checked: !sector.checked
                }
            } else {
                return {
                    ...sector
                }
            }
        })
        let latestSelectedSectors = []
        latestFilteredSectorList.forEach((sector) => {
            if(sector.checked){
                latestSelectedSectors.push(sector.id)
            }
        })
        
        this.setState({
            filteredSectorList: latestFilteredSectorList,
            sectorSelectedIds: latestSelectedSectors,
        }, () => {
            this.props.setSectors(this.state.sectorSelectedIds)
        })

    }

    handleAdditionalDetails = (event) => {
        this.props.setAdditionalDetails(event.target.value);
    }

    render() {
        const { filteredSectorList } = this.state;
        const { additionalDetails } = this.props;
        const userType = localStorage.getItem('user_type');
        
        return (
            <div className="form-wrapper edit-form-wrapper">
                <div className="studentForm-head-block">
                    {
                        userType==='alumni' ? <h6>Sector(s) relevant to my venture</h6> : <h6>Sector(s) of interest</h6>
                    }
                    <span>Three sectors maximum</span>
                </div>
                <div className="form-container student-venture-form">
                    { 
                        filteredSectorList.length ? (
                            filteredSectorList.map((sector, index) => (
                                <div 
                                    key={index}
                                    onClick={() => this.handleSectors(sector.id)}
                                    className={
                                        `venture-block ${
                                            sector.checked
                                            ? 'venture-selected' 
                                            : ''}`
                                    }
                                >
                                    <div className="venture-icon">
                                        <img src={sector.attributes['image-url']} alt="no_image" />
                                    </div>
                                    <span>{sector.attributes.name}</span>
                                </div>
                            ))
                        ) : null
                    }
                    {
                        userType==='student' || userType==='advisor' ? 
                        <div className="additional-block">
                        <Label>Additional Details (Optional) - Maximum 90 Characters</Label>
                        <textarea
                            maxLength="90" 
                            value={additionalDetails}
                            onChange={this.handleAdditionalDetails}></textarea>
                        </div> : ''
                    }
                </div>
            </div>
        );
    }
}

export default EditSectorForm;