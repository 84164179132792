import React from 'react';
import logo from "../../commons/images/logo.svg";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const Terms = (props) => {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className="aboutUs-modal privacy-policy-modal">
            <ModalHeader toggle={props.toggle} className="modal-head">
                <img src={logo} alt="Gradshack" />
            </ModalHeader>
            <ModalBody className="about-details privacy-policy-details">
                <h4>Terms Of Use</h4>
                <div className="content-in content-in-terms">
                    <div className="content-top">
                        <p>Collaborative Labs LLC, a New York limited liability company (“we” or “us”), welcomes you to our website (the <strong><em>“Site”</em></strong>) and the applications and services available from us, through the Site or other platforms (collectively with the Site, the <strong><em>“Services”</em></strong>). Your use of the Site and the Services are governed by these Terms of Use (these <strong><em>“Terms”</em></strong>). Any time you browse the Site or use the Services in any way, you agree to be bound by these Terms. If you do not agree to these Terms, do not use the Site or the Services.</p>
                        <p>Your use of the Services is also subject to our Privacy Policy, which is located on the Site, as well as any policies and procedures we publish from time to time (collectively, the <strong><em>“Policies”</em></strong>). We reserve the right to modify these Terms at any time, with such changes becoming effective when we post the modified Terms to the Site. We also reserve the right to make any changes to the Site and Services in any manner and to deny or terminate your access to the Site and Services at any time, for any reason or for no reason, even if you have an Account in our sole discretion.</p>
                        <p>Each time you use the Site or the Services, the then-current version of the Terms will apply. If you use the Site or the Services after a modification of these Terms, you agree to be bound by the Terms as modified.</p>
                        <p><strong><em>These Terms contain important information regarding your rights with respect to the Site and the Services, including your relationship with us, and include an arbitration provision that may limit your ability to pursue claims against us in court. Please read them carefully and review them regularly.</em></strong></p>
                    </div>
                    <ol>
                        <li>
                            <h5>Platform Overview.</h5>
                            <div>
                                <p>The Services include a web-based communications platform (the <strong><em>“Platform”</em></strong>) that enables connections between Platform users (<strong><em>“Users”</em></strong>) for certain purposes including, among others, idea generation and business development. </p>
                                <p>WE DO NOT EMPLOY YOU OR OTHER USERS TO PROVIDE OR PERFORM SERVICES, AND WE DO NOT SUPERVISE, DIRECT OR CONTROL USERS IN ANY MANNER, WHICH YOU HEREBY ACKNOWLEDGE.</p>
                                <p>The Platform enables connections between Users. We are not responsible for the performance of Users, nor do we have control over the integrity, responsibility, qualifications or any of the actions or omissions whatsoever of any Users. We make no representations about the suitability, reliability, timeliness, or accuracy of any services provided by Users identified through the Platform whether in public, private, or offline interactions.</p>
                            </div>
                        </li>
                        <li>
                            <h5>User Vetting.</h5>
                            <div>
                                <p>Users may be subject to a vetting process before they can register for and during their use of the Platform, including, but not limited to, a verification of identity, using third party services as appropriate. You hereby give consent to us to conduct background checks as often as required in compliance with federal and state laws, including, without limitation, the Fair Credit Reporting Act.</p>
                                <p>Although we may perform background checks of Users as outlined above, we cannot confirm that each User is who they claim to be and therefore, we cannot and do not assume any responsibility for the accuracy or reliability of identity or background check information or any information provided through the Platform.</p>
                                <p>When interacting with other Users, you should exercise caution and common sense to protect your personal safety and property, just as you would when interacting with other persons whom you do not know. NEITHER WE NOR OUR AFFILIATES OR LICENSORS IS RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF THE PLATFORM AND YOU HEREBY RELEASE US AND OUR EMPLOYEES, OFFICERS, DIRECTORS, MANAGERS, REPRESENTATIVES, AGENTS, AND OUR AND THEIR AFFILIATES AND LICENSORS (“RELEASED PARTIES”) FROM ANY LIABILITY RELATED THERETO. THE RELEASED PARTIES WILL NOT BE LIABLE FOR ANY CLAIM, INJURY OR DAMAGE ARISING IN CONNECTION WITH YOUR USE OF THE PLATFORM.</p>
                            </div>
                        </li>
                        <li>
                            <h5>Your Relationship with Us.</h5>
                            <div>
                                <p>The Platform is only a venue for connecting Users. Because we are not involved in any actual agreement between Users or in the completion of any services by any User, in the event that you have a dispute with one or more Users, you release us and our affiliates (and our and their respective managers, officers, directors, agents, investors, subsidiaries, and employees) from any and all claims, demands, or damages (actual or consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes.</p>
                                <p>WE EXPRESSLY DISCLAIM ANY LIABILITY THAT MAY ARISE BETWEEN USERS OF THE PLATFORM. </p>
                                <p><strong>TO THE EXTENT APPLICABLE, YOU HEREBY WAIVE THE PROTECTIONS OF CALIFORNIA CIVIL CODE § 1542 (AND ANY ANALOGOUS LAW IN ANY OTHER APPLICABLE JURISDICTION) WHICH SAYS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”</strong></p>
                            </div>
                        </li>
                        <li>
                            <h5>Public Areas and Acceptable Use.</h5>
                            <div className="content-top">
                                <p>The Platform may contain profiles, email systems, blogs, message boards, applications, chat areas, news groups, forums, communities and/or other message or communication facilities (“Public Areas”) that allow Users to communicate with other Users. You may only use such community areas to send and receive messages and material that are relevant and proper to the applicable forum. </p>
                                <p>You may not while using the Platform:</p>
                            </div>
                            <ul>
                                <li>Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as but not limited to, rights of privacy and publicity) of others, including our staff, or use information learned from the Platform to otherwise defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of any other user or our staff outside of the Platform.</li>
                                <li>Publish, post, upload, distribute or disseminate any profane, defamatory, infringing, obscene or unlawful topic, name, material or information.</li>
                                <li>Use the Platform in violation of local, state, national, or international law.</li>
                                <li>Upload files that contain software or other material that violates the intellectual property rights (or rights of privacy or publicity) of any third party.</li>
                                <li>Upload files that contain viruses, Trojan horses, corrupted files, or any other similar software that may damage the operation of another’s computer.</li>
                                <li>Impersonate another person or a User or allow any other person or entity to use your identification to post or view comments.</li>
                                <li>Restrict or inhibit any other User from using and enjoying the Public Areas.</li>
                                <li>Imply or state that any statements you make are endorsed by us without our prior written consent.</li>
                                <li>Use a robot, spider, manual and/or automatic processes or devices to data-mine, data-crawl, scrape or index the Platform in any manner.</li>
                                <li>Hack or interfere with the Platform, its servers or any connected networks.</li>
                                <li>Adapt, alter, decode, reverse engineer, license, sublicense or translate the Platform for your own personal or commercial use.</li>
                                <li>Remove or alter, visually or otherwise, any copyrights, trademarks or proprietary marks and rights owned by us.</li>
                                <li>Upload content that is offensive and/or harmful, including, but not limited to, content that advocates, endorses, condones or promotes racism, bigotry, hatred or physical harm of any kind against any individual or group of individuals.</li>
                                <li>Upload content that provides materials or access to materials that are violent or of a sexual nature.</li>
                                <li>Use the Platform to solicit for any other business, website or service, or otherwise contact Users for employment, contracting or any purpose not related to use of the Platform as set forth herein.</li>
                                <li>Use the Platform to collect usernames and or/email addresses of Users by electronic or other means.</li>
                                <li>Use the Platform in violation of any policies we put into place.</li>
                                <li>Use the Platform in a manner which is false or misleading (directly, indirectly, or by omission or failure to update information) or for the purpose of accessing or otherwise obtaining our trade secret information for public disclosure or other purposes.</li>
                                <li>Attempt to circumvent the payments system or service fees in anyway including, but not limited to, processing payments outside of the platform, including inaccurate information on invoices, or otherwise invoicing in a fraudulent manner;</li>
                                <li>Register under different usernames or identities, after your Account has been suspended or terminated or register under multiple usernames or identities.</li>
                                <li>Cause any third party to engage in the restricted activities above.</li>
                            </ul>
                            <p>You understand that all submissions made to Public Areas will be public and that you will be publicly identified by your name or login identification when communicating in Public Areas, and we will not be responsible for the action of any Users with respect to any information or materials posted in Public Areas.</p>
                        </li>
                        <li>
                            <h5>Eligibility.</h5>
                            <div>
                                <p>When you use the Services, you represent that you are (i) at least the age of majority in the jurisdiction where you reside or (ii) if you have not reached the age of majority in the jurisdiction where you reside, that you have received permission to use the Services from your parent or legal guardian.</p>
                                <p>You represent that any information you submit to us when using the Services is accurate, truthful, and current. You also represent that your use of the Services does not violate any applicable law or regulation. </p>
                            </div>
                        </li>
                        <li>
                            <h5>Registration & Account. </h5>
                            <p>Certain of the Services or portions of the Site may require you to register for an account (<strong><em>“Account”</em></strong>), becoming a <strong><em>“Registered User”</em></strong>. As part of the Account creation process, you may be asked to provide a username and password unique to the Account (<strong><em>“Login Information”</em></strong>). You are responsible for the confidentiality and use of your Login Information and agree not to transfer or disclose your Login Information to any third party other than an individual with express authority to act on your behalf. If you suspect any unauthorized use of your Account, you agree to notify us immediately. You are solely responsible for any activities occurring under your Account. You have no ownership right to your Account.  If you are registering an Account as the authorized user of an organization, that organization may have administrator rights to access your account and any information provided under your Account. </p>
                        </li>
                        <li>
                            <h5>Permitted Uses/License.</h5>
                            <div>
                                <p>You are authorized to access the Site for the sole purpose of viewing and using the Services on your computer or device. We authorize you to copy materials from the Services to your hard drive solely for the purpose of viewing and using the Services on your computer.</p>
                                <p>You may not decompile, disassemble, rent, lease, loan, sell, sublicense, or create derivative works from the Site, the Services, or any data thereon. You may not use any robot, spider, or other automatic device or manual process to monitor or copy the Site or its content without our prior written permission. Your failure to abide by these conditions will immediately terminate your right to access the Site or to use the Services and may violate our intellectual property rights or the intellectual property rights of third parties.</p>
                            </div>
                        </li>
                        <li>
                            <h5>Location-based Services.</h5>
                            <p>Some of the Services may require that location functionality be enabled on the relevant device in order to work properly. You acknowledge and agree that if location permissions and functionalities are not enabled on the device with which you access the Services, the Services may not work appropriately or at all. We will use any location information we receive from you in accordance with our Privacy Policy.</p>
                        </li>
                        <li>
                            <h5>Third-Party Sites.</h5>
                            <p>The Site may contain links to websites we do not operate, control, or maintain (<strong><em>“Third Party Websites”</em></strong>). We do not endorse any Third-Party Websites, and we make no representation or warranty in any respect regarding the Third Party Websites. Any links to Third Party Websites on the Site are provided solely for your convenience. If you do access any Third Party Websites, you do so at your own risk and waive any and all claims against us regarding the Third Party Websites or our links thereto.</p>
                        </li>
                        <li>
                            <h5>User Content Generally</h5>
                            <p>When you post content and information to the Site or in connection with the Services (<strong><em>“User Content”</em></strong>), you represent and warrant to us that (i) you own or have rights to use the User Content, (ii) the posting of the User Content does not violate any rights of any person or entity, and (iii) you have no agreement with or obligations to any third party that would prohibit your use of the Site or Services in the manner so used. You agree to pay all royalties, fees, and any other monies owing to any person or entity by reason of any User Content posted by you to the Site or through the Services. We will have access to User Content you post in any form on the Site or Services, including messages with other users.</p>
                        </li>
                        <li>
                            <h5>User Conduct. </h5>
                            <p>You agree not to use the Site or the Services to take any action or actions that (including with respect to any User Content): (i) is patently offensive in any manner (as determined in our sole discretion), (ii) involve commercial activities without our prior written consent, such as contests or sweepstakes, (iii) are contrary to our public image, goodwill, or reputation, (iv) infringe on our or any third party’s intellectual property rights, (v) violates any law or any third party’s legal rights, or (vi) “frame” or “mirror” any part of the Site without our prior written consent. </p>
                        </li>
                        <li>
                            <h5>Data. </h5>
                            <p>You agree that we have the right to collect and analyze data and other information relating to the provision, use, and performance of various aspects of the Site and Services, and related systems (for example, anonymous and aggregated information concerning user behavior and use of the Services), and we will be free (during and after the term hereof) to (i) use such information and data to improve and enhance the Site Services and for other development, diagnostic and corrective purposes in connection with the Site and Services and other of our offerings, and (ii) disclose such data solely in aggregate or other de-identified forms in connection with its business.</p>
                        </li>
                        <li>
                            <h5>Paid Services. </h5>
                            <div>
                                <p>We may require Services to be paid for on a recurring basis (<strong><em>“Subscription Services”</em></strong>) or on an as-used basis (<strong><em>“A La Carte Services”</em></strong> and, together with the Subscription Services, <strong><em>“Paid Services”</em></strong>). We have the right to change, delete, discontinue or impose conditions on Paid Services or any feature or aspect of a Paid Service. Subscription Services may subject you to recurring fees and/or terms. By signing up for a Subscription Service, including after any free trial period, you agree to pay us the subscription fee and any applicable taxes as set forth in your Account settings or as otherwise agreed in writing (<strong><em>“Subscription Fee”</em></strong>). A La Carte Services may subject you to fees charged per usage and/or terms. By using an A La Carte Service, you agree to pay the fees and any taxes incurred at the time of usage (<strong><em>“A La Carte Fees”</em></strong> and, together with Subscription Fees, the <strong><em>“Paid Service Fees”</em></strong>).</p>
                                <p>Paid Service Fees may be paid by credit card, debit card, or other payment forms we may permit. If you link a debit or credit card to your Account, you authorize us to collect Paid Service Fees by debit from your linked debit card or charge to your linked credit card.</p>
                                <p>Unless otherwise provided in a Subscription Service’s terms, Subscription Fees will be charged on the 1st of every month until canceled. You may cancel a Subscription Service at any time from your Account Settings. If you cancel a Subscription Service, you will continue to have access to that Subscription Service through the end of your then current billing period, but you will not be entitled to a refund or credit for any Subscription Fee already due or paid. We reserve the right to change our Subscription Fee upon thirty (30) days’ advance notice. Your continued use of Subscription Services after notice of a change to our Subscription Fee will constitute your agreement to such changes.</p>
                            </div>
                        </li>
                        <li>
                            <h5>Copyright Infringement.</h5>
                            <p>We respect the intellectual property rights of others. The Digital Millennium Copyright Act of 1998 (the <strong><em>“DMCA”</em></strong>) provides a complaint procedure for copyright owners who believe that website material infringes their rights under U.S. copyright law. If you believe that your work has been improperly copied and posted on the website, please provide us with the following information: (i) name, address, telephone number, email address and an electronic or physical signature of the copyright owner or of the person authorized to act on his/her behalf; (ii) a description of the copyrighted work that you claim has been infringed; (iii) a description of where on the Site the material that you claim is infringing is located; (iv) a written statement that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and (v) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf. These requirements must be followed to give us legally sufficient notice of infringement. Send copyright infringement complaints to the following email address: contact@gradshack.com. We suggest that you consult your legal advisor before filing a DMCA notice with our copyright agent. There can be penalties for false claims under the DMCA.</p>
                        </li>
                        <li>
                            <h5>Warranty Disclaimer.</h5>
                            <div>
                                <p><strong>You agree that the Services are available on an “as is” basis, without any warranty, and that you use the Services at your own risk. We disclaim, to the maximum extent permitted by law, any and all warranties, whether express or implied, including, without limitation, (a) warranties of merchantability or fitness for a particular purpose, (b) warranties against infringement of any third party intellectual property or proprietary rights, (c) warranties relating to delays, interruptions, errors, or omissions in the Services or on the Site, (d) warranties relating to the accuracy or correctness of data on the Services, and (e) any other warranties otherwise relating to our performance, nonperformance, or other acts or omissions.</strong></p>
                                <p><strong>We do not warrant that the Site or the Services will operate error-free or that the Site is free of computer viruses and/or other harmful materials. If your use of the Site or the Services results in the need for servicing or replacing equipment or data, we are not responsible for any such costs.</strong></p>
                                <p>Some jurisdictions do not allow the exclusion or limitation of certain categories of damages or implied warranties; therefore, the above limitations may not apply to you. In such jurisdictions, our liability is limited to the greatest extent permitted by law.</p>
                            </div>
                        </li>
                        <li>
                            <h5>Limitation of Liability.</h5>
                            <div>
                                <p><strong>Any liability we have to you in connection with these Terms, under any cause of action or theory, is strictly limited to, in aggregate for all violations, the amount paid to you by us for your use of the Services in the six month period immediately preceding the events giving rise to the claim. Without limiting the previous sentence, in no event shall we or any of our affiliates be liable to you for any indirect, special, incidental, consequential, punitive, or exemplary damages arising out of or in connection with, these Terms. The foregoing limitations apply whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if we or our affiliates have been advised of the possibility of such damages. </strong></p>
                                <p><strong>You agree to indemnify and hold us harmless for any breach of security or any compromise of your Account.</strong></p>
                                <p>Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages; therefore, the above limitations may not apply to you. In such jurisdictions, our liability is limited to the greatest extent permitted by law.</p>
                                <p>We do not control or direct what people and others do or say, and we are not responsible for their actions or conduct (whether online or offline) or any content they share (including offensive, inappropriate, obscene, unlawful, and other objectionable content).</p>
                            </div>
                        </li>
                        <li>
                            <h5>Indemnification.</h5>
                            <p>You agree to indemnify and hold harmless us, our affiliates and our and their officers, directors, partners, agents, and employees from and against any loss, liability, claim, or demand, including reasonable attorneys’ fees (collectively, “Claims”), made by any third party due to or arising out of your use of the Site and Services in violation of these Terms, any breach of the representations and warranties you make in these Terms, or your User Content. You agree to be solely responsible for defending any Claims against or suffered by us, subject to our right to participate with counsel of our own choosing.</p>
                        </li>
                        <li>
                            <h5>Electronic Signatures and Notices.</h5>
                            <div>
                                <p>Certain activities on the Services may require you to make an electronic signature. You understand and accept that an electronic signature has the same legal rights and obligations as a physical signature.</p>
                                <p>If you have an Account, you agree that we may provide you any and all required notices electronically through your Account or other electronic means. You agree that we are not responsible for any delivery fees charged to you as a result of your receipt of our electronic notices.</p>
                            </div>
                        </li>
                        <li>
                            <h5>Governing Law. </h5>
                            <p>These Terms are governed by New York law, without giving effect to conflicts of law principles. You agree that, to the extent applicable and expressly subject to the dispute resolution provisions below, to submit to the exclusive jurisdiction of the state and federal courts located in the city, state and county of New York in circumstances where these Terms permit litigation in court.</p>
                        </li>
                        <li>
                            <h5>Dispute Resolution.</h5>
                            <div>
                                <p><strong>Please read this Section 20 (this “Section”) carefully. It contains procedures for mandatory binding arbitration and a class action waiver.</strong></p>
                                <p><em>Notice Requirement and Informal Dispute Resolution.</em>  Before either we or you may seek arbitration, the party seeking arbitration must send the other party a written Notice of Dispute (<strong><em>“Notice”</em></strong>) describing the nature and basis of the claim or dispute and the requested relief.  A Notice to us should be sent to Collaborative Labs LLC, 950 Third Avenue, 31st Floor, New York, NY 10022.  After the Notice is received, you and we may attempt to resolve the claim or dispute informally.  If we do not resolve the claim or dispute within thirty (30) days after the Notice is received, either party may begin an arbitration proceeding. The amount of any settlement offer made by any party may not be disclosed to the arbitrator until after the arbitrator has determined the amount of the award, if any, to which either party is entitled.</p>
                                <p><em>Arbitration Rules.</em> Arbitration shall be initiated through the American Arbitration Association (<strong><em>“AAA”</em></strong>), an established alternative dispute resolution provider (<strong><em>“ADR Provider”</em></strong>) that offers arbitration as set forth in this section.  If AAA is not available to arbitrate, the parties shall agree to select an alternative ADR Provider.  The rules of the ADR Provider shall govern all aspects of the arbitration, including but not limited to the method of initiating and/or demanding arbitration, except to the extent such rules are in conflict with the Terms.  The AAA Commercial Arbitration Rules (the <strong><em>“Arbitration Rules”</em></strong>) governing the arbitration are available online at www.adr.org or by calling the AAA at 1-800-778-7879.  The arbitration shall be conducted by a single, neutral arbitrator.  Any claims or disputes where the total amount of the award sought is less than Ten Thousand U.S. Dollars (US $10,000.00) shall be resolved through binding non-appearance-based arbitration.  For claims or disputes where the total amount of the award sought is Ten Thousand U.S. Dollars (US $10,000.00) or more, the right to a hearing will be determined by the Arbitration Rules. Any hearing will be held in the city, state and county of New York unless the parties agree otherwise. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.  Each party shall bear its own costs (including attorney’s fees) and disbursements arising out of the arbitration and shall pay an equal share of the fees and costs of the ADR Provider.</p>
                                <p><em>Additional Rules for Non-Appearance Based Arbitration.</em> The arbitration shall be conducted by telephone, online and/or based solely on written submissions; the specific manner shall be chosen by the party initiating the arbitration.  The arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise agreed by the parties.</p>
                                <p><em>Time Limits.</em>  If either you or we pursue arbitration, the arbitration action must be initiated and/or demanded within the statute of limitations (i.e., the legal deadline for filing a claim) and within any deadline imposed under the AAA Rules for the pertinent claim.</p>
                                <p><em>Authority of Arbitrator.</em>  If arbitration is initiated, the arbitrator will decide the rights and liabilities, if any, of the parties involved, and the dispute will not be consolidated with any other matters or joined with any other cases or parties.  The arbitrator shall have the authority to grant motions dispositive of all or part of any claim.  The arbitrator shall have the authority to award monetary damages, and to grant any non-monetary remedy or relief available to an individual under applicable law, the Arbitration Rules, and these Terms.  The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded.  The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have.  The award of the arbitrator is final and binding upon you and us.</p>
                                <p><em>Waiver of Jury Trial.</em>  THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead electing that all claims and disputes shall be resolved by arbitration under these terms.  Arbitration procedures are typically more limited, more efficient and less costly than rules applicable in a court and are subject to very limited review by a court.  In the event, any litigation should arise between you and us in any state or federal court in a suit to vacate or enforce an arbitration award or otherwise, YOU AND WE WAIVE ALL RIGHTS TO A JURY TRIAL, instead electing that the dispute is resolved by a judge.</p>
                                <p><em>Waiver of Class or Consolidated Actions.</em>  ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS SECTION MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER USER. </p>
                                <p><em>Confidentiality.</em>  All aspects of the arbitration proceeding, including but not limited to the award of the arbitrator and compliance therewith, shall be strictly confidential.  You agree to maintain confidentiality unless otherwise required by law.  This paragraph shall not prevent a party from submitting to a court of law any information necessary to enforce this Section, to enforce an arbitration award, or to seek injunctive or equitable relief.</p>
                                <p><em>Severability.</em>  If any part or parts of this Section are found under the law to be invalid or unenforceable by a court of competent jurisdiction, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of this Section shall continue in full force and effect.</p>
                                <p><em>Right to Waive.</em>  Any or all of the rights and limitations set forth in this Section may be waived by the party against whom the claim is asserted.  Such waiver shall not waive or affect any other portion of this Section.</p>
                                <p><em>Survival of Agreement.</em>  This Section will survive the termination of your relationship with us. </p>
                                <p><em>Small Claims Court.</em>  Notwithstanding the foregoing, either you or we may bring an individual action in small claims court.</p>
                                <p><em>Emergency Equitable Relief.</em>  Notwithstanding the foregoing, either party may seek emergency equitable relief, including but not limited to an injunction, before a state or federal court in order to maintain the status quo pending arbitration.  A request for interim measures shall not be deemed a waiver of any other rights or obligations under this Section.</p>
                                <p><em>Claims Not Subject to Arbitration.</em> Notwithstanding the foregoing, claims of defamation, violation of the Computer Fraud and Abuse Act, and infringement or misappropriation of our patent, copyright, trademark or trade secrets rights shall not be subject to this Section.</p>
                            </div>
                        </li>
                        <li>
                            <h5>Notice for California Users. </h5>
                            <p>Under California Civil Code Section 1789.3, California Website users are entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone at (800) 952-5210.</p>
                        </li>
                        <li>
                            <h5>Miscellaneous.</h5>
                            <p>We may assign, transfer, delegate, or otherwise hypothecate our rights under these Terms in our sole discretion. If we fail to enforce a provision of these Terms, you agree that such a failure does not constitute a waiver to enforce the provision (or any other provision hereunder). If any provision of these Terms is held or made invalid, the invalidity does not affect the remainder of these Terms. We reserve all rights not expressly granted in these Terms and disclaim all implied licenses.</p>
                        </li>
                    </ol>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default Terms;