import { combineReducers } from 'redux'
import redirectAfterLogin from './redirectAfterLogin'
import userType from './userType'
import yourInfoForm from './userForms/yourInfoForm'
import yourVentureForm from './userForms/yourVentureForm'
import yourSectorForm from './userForms/yourSectorForm'
import additionalDetailsTab from './userForms/additionalDetailsTab'
import userFormTab from './userForms/userFormTab'
import desktopHeader from './homePage/desktopHeader'
import homePage from './homePage/homePage'
import filterUsers from './filterUsers'
import newsFeed from './newsFeed'
import userNewsFeed from './userNewsFeed'
import newSignUpUserCard from './newSignUpUserCard'
import favouriteTimelineItem from './favouriteTimelineItem'
import filters from './filters'
import favourites from './favourites'
import adminLogin from './admin/adminLogin'
import adminHomePage from './admin/adminHomePage'
import twilioChat from './twilioChat'
import schoolsModal from './schoolsModal'
import validationError from './validationError'
import adminExistingUsers from './admin/adminExistingUsers'
import pagination from './pagination'
import signUp from './userForms/userSignUp'
import loginUser from './userForms/loginUser'
import profile from './profile'

export default combineReducers({
    redirectAfterLogin,
    userType,
    yourInfoForm,
    yourVentureForm,
    yourSectorForm,
    additionalDetailsTab,
    userFormTab,
    desktopHeader,
    homePage,
    filterUsers,
    newsFeed,
    userNewsFeed,
    newSignUpUserCard,
    favouriteTimelineItem,
    filters,
    favourites,
    adminLogin,
    adminHomePage,
    twilioChat,
    schoolsModal,
    validationError,
    adminExistingUsers,
    pagination,
    signUp,
    loginUser,
    profile,
})
