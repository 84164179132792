import React, { Component } from "react";
import { withRouter } from "react-router";
import DesktopHeader from "../../containers/homePage/DesktopHeader";
import MobileMenu from "../../containers/homePage/MobileMenu";

class Match extends Component {
    componentDidMount() {}

    scheduleMeeting() {
        window.open("https://calendly.com/gradshack");
    }

    render() {
        return (
            <React.Fragment>
                <DesktopHeader />
                <div className="match-wrapper screen-height">
                    <div className="edit-details-container about-us-subheader" />
                    <div className="custom-profile-container match-container">
                        <div className="match-content">
                            <h4 className="match-subtitle">
                                If you&apos;re searching for a cofounder or other key teammate, we&apos;d be happy to
                                learn more about your objectives and criteria and see if anyone in our network is a fit.
                            </h4>
                            <button className="match-btn btn btn-secondary" onClick={this.scheduleMeeting}>
                                Click here to schedule a call with us
                            </button>
                        </div>
                    </div>
                </div>
                <MobileMenu />
            </React.Fragment>
        );
    }
}

export default withRouter(Match);
