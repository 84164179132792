import { connect } from 'react-redux';
import UserFormButtons from '../../components/userForms/userFormButtons/UserFormButtons';
import { createNamespacer } from '../../utils/reducers';
import { USERFORMDATA, USERTYPE, ADDITIONALDETAILSTAB } from '../../constants/namespacer';
import { putUserData } from '../../api/userForms/putUserData'

const userFormDataNamespacer = createNamespacer(USERFORMDATA);
const userTypeNamespacer = createNamespacer(USERTYPE);
const additionalDetailsTabNamespacer = createNamespacer(ADDITIONALDETAILSTAB);

const mapStateToProps = (state) => {
    return {
        profilePicUrl: state.yourInfoForm.profilePicUrl,
        name: state.yourInfoForm.name,
        lastName: state.yourInfoForm.lastName,
        email: state.yourInfoForm.email,
        linkedinProfileUrl: state.yourInfoForm.linkedinProfileUrl,
        mbaProgram: state.yourInfoForm.mbaProgram,
        colOrUniv: state.yourInfoForm.colOrUniv,
        graduationYear: state.yourInfoForm.graduationYear,
        currentWorkLocation: state.yourInfoForm.currentWorkLocation,
        concept: state.yourVentureForm.concept,
        venture: state.yourVentureForm.venture,
        needs: state.yourVentureForm.needs,
        sectors: state.yourSectorForm.sectors,
        currentFormTab: state.userFormTab.currentFormTab,
        additionalDetails: state.additionalDetailsTab.additionalDetails,
        privacyCheckboxIsChecked: state.yourInfoForm.privacyCheckboxIsChecked,
        userType: state.userType.userType,
        isInvestor: state.userType.isInvestor,
        feasibilityStudyAmount: state.yourVentureForm.feasibilityStudyAmount,
        investorType: state.yourVentureForm.investorType,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        clearFormData: async() => {
            dispatch({
                type: userFormDataNamespacer('SET_MBA_PROGRAM_VALUE'),
                mbaProgram: ''
            })
            dispatch({
                type: userFormDataNamespacer('SET_COL_OR_UNIV_VALUE'),
                colOrUniv: ''
            })
            dispatch({
                type: userFormDataNamespacer('SET_GRADUATION_YEAR_VALUE'),
                graduationYear: ''
            })
            dispatch({
                type: userFormDataNamespacer('SET_LINKEDIN_PROFILE_URL_VALUE'),
                linkedinProfileUrl: '',
            })
            dispatch({
                type: userFormDataNamespacer('SET_CURRENT_WORK_LOCATION_VALUE'),
                currentWorkLocation: ''
            })
            dispatch({
                type: userFormDataNamespacer('SET_CONCEPT_VALUE'),
                concept: ''
            })
            dispatch({
                type: userFormDataNamespacer('SET_VENTURE_VALUE'),
                venture: ''
            })
            dispatch({
                type: userFormDataNamespacer('SET_NEEDS_VALUE'),
                needs: ''
            })
            dispatch({
                type: userFormDataNamespacer('SET_SECTORS_VALUE'),
                sectors: []
            })
            dispatch({
                type: userFormDataNamespacer('SET_PRIVACY_CHECK_VALUE'),
                privacyCheckboxIsChecked: false
            })
            dispatch({
                type: userTypeNamespacer('SET_USERTYPE_VALUE'),
                userType: ''
            })
            dispatch({
                type: userTypeNamespacer('SET_IS_INVESTOR_VALUE'),
                isInvestor: false,
            })
            dispatch({
                type: additionalDetailsTabNamespacer('SET_ADDITIONALDETAILSTAB_VALUE'),
                additionalDetails: ''
            })
        },
        saveUserData: async(bodyFormData) => {
            try{
                let response = await putUserData(bodyFormData);
                if(response.status===200){
                }
            }
            catch(error){
                console.log(error)
            }
        },   
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserFormButtons);