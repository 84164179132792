import { connect } from 'react-redux';
import { createNamespacer } from '../../utils/reducers';
import { TWILIOCHAT } from '../../constants/namespacer';
import { getSecondUserDetailApi } from '../../api/twilioChat'
import Messages from '../../components/messages/Messages';
import chatClient from '../../services/twilio';
import { getMessageCount } from '../../constants/fixedValues'

const twilioChatNamespacer = createNamespacer(TWILIOCHAT);

const mapStateToProps = (state) => {
    return {
        msgFromIdentity: state.twilioChat.msgFromIdentity,
        messages: state.twilioChat.messages,
        sendMessageText: state.twilioChat.sendMessageText,
        tcCurrentChannel: state.twilioChat.tcCurrentChannel,
        subscribedChannels: state.twilioChat.subscribedChannels,
        secondUserData: state.twilioChat.secondUserData,
        activeUserChatIndex: state.twilioChat.activeUserChatIndex,
        twilioToken: state.twilioChat.twilioToken,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        clrStates: () => {
            dispatch({
                type: twilioChatNamespacer('SET_TC_CURRENT_CHANNEL'),
                tcCurrentChannel: null,
            })
            dispatch({
                type: twilioChatNamespacer('SET_MESSAGES'),
                messages: [],
            })
            dispatch({
                type: twilioChatNamespacer('SET_SECOND_USER_DATA'),
                secondUserData: null,
            })
        },
        setActiveUserChatIndex: (value) => {
            dispatch({
                type: twilioChatNamespacer('SET_ACTIVE_USER_CHAT_INDEX'),
                activeUserChatIndex: value,
            })
        },
        get getUserSubscribedChannels(){
            return async (msgFromIdentity) => {
                try{
                    const subscribedChannels = await chatClient.client.getSubscribedChannels();
                    const channelWithOtherDetails = await this.getChannelWithOtherDetails(subscribedChannels.items, msgFromIdentity);
                    
                    const filterChannelWithOtherDetails = channelWithOtherDetails.filter(channel => {
                        return channel.secondUserDetail !== undefined && channel.latestMessage !== undefined;
                    })
                    
                    filterChannelWithOtherDetails.sort((a, b) => b.latestMessageTimeStamp - a.latestMessageTimeStamp);

                    dispatch({
                        type: twilioChatNamespacer('SET_SUBSCRIBED_CHANNEL'),
                        subscribedChannels: filterChannelWithOtherDetails,
                    })

                }
                catch(error){
                    console.log(error)
                }
            }
        },
        get getChannelWithOtherDetails(){
            return async (subscribedChannelsArr, msgFromIdentity) => {
                try{
                    return await Promise.all(subscribedChannelsArr.map(async (channel) => {
                        const secondUserId = channel.uniqueName.split('-').filter((userId) => (
                            userId !== msgFromIdentity
                        ))

                        let secondUserDetail;
                        if (secondUserId.length === 1){
                            secondUserDetail = await this.getSecondUserDetail(secondUserId[0], false);
                        }
                        
                        const messages = await channel.getMessages(getMessageCount);
                        const totalMessagesCountOnChannel = messages.items.length;

                        let latestMessage;
                        let latestMessageTimeStamp;

                        if(totalMessagesCountOnChannel>0){
                            latestMessage = messages.items[totalMessagesCountOnChannel-1].body
                            latestMessageTimeStamp = messages.items[totalMessagesCountOnChannel-1].timestamp
                        }
                        return({channel, secondUserDetail, messages, latestMessage, latestMessageTimeStamp})
                    }))
                }
                catch(error){
                    console.log(error)
                }
            }
        },
        
        get setCurrentChannelAndMessages(){
            return async (channelDetail) => {
                try{
                    const channel = channelDetail.channel;
                    chatClient.attachHandlersToChannel(channel);

                    const lastMessageIndex = channelDetail.channel.lastMessage.index;
                    await channel.updateLastConsumedMessageIndex(lastMessageIndex);

                    dispatch({
                        type: twilioChatNamespacer('SET_TC_CURRENT_CHANNEL'),
                        tcCurrentChannel: channel,
                    })
                    dispatch({
                        type: twilioChatNamespacer('SET_SECOND_USER_DATA'),
                        secondUserData: channelDetail.secondUserDetail,
                    })
                    dispatch({
                        type: twilioChatNamespacer('SET_MESSAGES'),
                        messages: channelDetail.messages.items,
                    })
                }
                catch(error){
                    console.log(error)
                }
            }
        },
        get getSecondUserDetail(){
            return async (secondUserId, setReduxState) => {
                try{
                    let response  = await getSecondUserDetailApi(secondUserId);
                    if(response.status===200){
                        if(setReduxState){
                            dispatch({
                                type: twilioChatNamespacer('SET_SECOND_USER_DATA'),
                                secondUserData: response.data.data,
                            })
                        }
                        else{
                            return response.data.data;
                        }
                    } 
                }
                catch(error){
                    console.log(error)
                }
            }
            
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);

