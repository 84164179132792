import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

class ConfirmLogoutModal extends Component {
    render() {
        return(
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}
                className="modal-dialog-centered modal-xl new-post-modal confirm-logout-modal"
            >
                <ModalHeader toggle={this.props.toggle} className="modal-head">
                    Are you sure you want to logout?
                </ModalHeader>
                <ModalBody className="modal-body ">
                    <div className="confirm-logout-modal__button-wrapper">
                        <Button
                            className="confirm-logout-modal__button"
                            color="primary"
                            onClick={() => (this.props.onConfirmLogout())}
                        >
                            Yes
                        </Button>
                        <Button
                            className="confirm-logout-modal__button confirm-logout-modal__secondary-button"
                            color="secondary"
                            onClick={() => (this.props.toggle())}
                        >
                            No
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        );
    }
}

export default ConfirmLogoutModal;
