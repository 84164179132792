import React from 'react'

const formatUniversityName = (university) => {
    return university.nickname || university.name;
}

export default function ChatViewProfile({ userDetail, openProfileSection }) {
    return (
        <div className={'view-profile-container'+(openProfileSection? ' show-profile':'')}>
            {
                userDetail ?
                <div className="location-info">
                    <div className="location-details">
                        <span>
                            {formatUniversityName(userDetail.attributes.university)} {userDetail.attributes['mba-program']} {userDetail.attributes['grad-year']}
                        </span>
                        <span>{userDetail.attributes.location.name}, {userDetail.attributes.location.code}</span>
                    </div>
                    {
                        userDetail.attributes['profile-url'] ?
                        <a
                            href={userDetail.attributes['profile-url']}
                            className="chat-linkedIn"
                            rel="noopener noreferrer"
                            target="_blank">LinkedIn Profile
                        </a> : ''
                    }
                </div> : ''
            }
            {
                userDetail ?
                <div className="chat-basic-info">
                {
                    userDetail.attributes['user-type']==='alumni' ?
                    <>
                        <div className="basic-info">
                            <span>My concept...</span>
                            <p>{userDetail.attributes['alumni-detail'].concept}</p>
                        </div>
                        <div className="basic-info">
                            <span>Status of venture...</span>
                            <p>{userDetail.attributes['alumni-detail'].state.split('_').join(' ').charAt(0).toUpperCase()}{userDetail.attributes['alumni-detail'].state.split('_').join(' ').substring(1)}</p>
                        </div>
                        <div className="basic-info">
                            <span>What I need today...</span>
                            {
                                userDetail.attributes['alumni-detail']['need-today'].map((alumniNeedToday, index) => (
                                    <p className="card-value" key={index}>{
                                        `${alumniNeedToday.split('_').join(' ').charAt(0).toUpperCase()}${alumniNeedToday.split('_').join(' ').substring(1)}`
                                    }</p>
                                ))
                            }
                        </div>
                    </> : ''
                }
                {
                    userDetail.attributes['user-type']==='student' || userDetail.attributes['user-type']==='advisor' ?
                    <>
                        <div className="basic-info">
                            <span>Wants to disrupt...</span>
                            {
                                userDetail.attributes.interests.map((studentInterest, index) => (
                                    <p
                                        key={index}
                                        className="sector-value"
                                    >
                                        {studentInterest.name}
                                    </p>
                                ))
                            }
                        </div>
                        <div className="basic-info">
                            <p className='italic-text'>
                                {
                                    userDetail.attributes['interest-desc'] ?
                                    `"${userDetail.attributes['interest-desc']}"` : ''
                                }
                            </p>
                        </div>
                    </> : ''
                }
                </div> : ''
            }
        </div>
    )
}
