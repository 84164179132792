import axiosInstance from '..';

export const getTimelineFeedList = (params) => {
    const axios = axiosInstance();
    
    return axios({
        method: 'GET',
        url: `/timelines/feeds?${params}`,
    })
};

export const getTimelineFeedListWithFilter = (params) => {
    const axios = axiosInstance();

    let url = params.length>0 ? `timelines/feeds?${params}` : 'timelines/feeds';
    
    return axios({
        method: 'GET',
        url,
    })
};
