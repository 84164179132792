import axiosInstance from '.';

export const getTwilioTokenApi = () => {
    return axiosInstance()({
        method: 'POST',
        url: '/chats',
    })
}

export const addMemberToChannelApi = (data) => {
    return axiosInstance()({
        method: 'POST',
        url: '/chats/add_member',
        data,
    })
}

export const getSecondUserDetailApi = (userId) => {
    return axiosInstance()({
        method: 'GET',
        url: `/users/${userId}`,
    })
}