import { Provider } from "react-redux";
import React, { Component } from "react";
import GAListener from './gaListener/GAListener';
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import { GA_TRACKING_ID } from "../../constants/googleAnalyticsConfig";
import store from "../../store";
import Routes from '../routes/Routes'

class App extends Component {

    componentDidMount() {
        this.props.checkInitialSettings();

        if (window.location.pathname === "/") {
            document.body.classList.add("white-bg");
        }
        if(window.location.pathname ===  '/adminLogin'){
            document.body.classList.add("admin-login-page");
        }
        if (
            window.location.pathname === "/userInfo" ||
            window.location.pathname === "/thankYou"
        ) {
            document.body.classList.add("form-bg");
        } else {
            document.body.classList.remove("white-bg");
            document.body.classList.remove("form-bg");
        }
    }
    
    render() {
        const { isAdmin, isTokenValid } = this.props;
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <GAListener trackingId={GA_TRACKING_ID}>
                        <div className="App">
                            <Routes
                                isAdmin={isAdmin}
                                isTokenValid={isTokenValid}
                                getUserData={this.props.getUserData}
                            />
                        </div>
                    </GAListener>
                </BrowserRouter>
            </Provider>
        );
    }
}

export default App;
