import React, {Component} from 'react';
import no_users from "../../commons/images/no-users.svg";
import cx from 'classnames';

class NoUsers extends Component {
    render() {
        return (
            <React.Fragment>
                <div className={cx("screen-height", this.props.className)}>
                    <div className="custom-profile-container no-users">
                        <div className="no-user-icon">
                            <img src={no_users} alt="no_image"/>
                        </div>
                        <span className="no-users-msg">No profiles found!</span>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default NoUsers;