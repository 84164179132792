import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class SubscribeModal extends Component {
    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="favourite-modal">
                <ModalHeader toggle={this.props.toggle} className="modal-head">You have successfully</ModalHeader>
                <ModalBody className="favourite-details">
                    <span className="favourite-name">Subscribed</span>
                </ModalBody>
            </Modal>
        );
    }
}

export default SubscribeModal;