import React, { Component } from "react";
import ChatHeader from './chatHeader/ChatHeader';
import ChatSection from '../../containers/userChat/ChatSection';

class UserChat extends Component {
    state = {  }
    constructor(props){
        super(props)
        this.state={
            openProfileSection: false,
            isMinimizeChat: false
        }
        this.viewProfile = this.viewProfile.bind(this);
        this.minimizeChat = this.minimizeChat.bind(this);
    }

    viewProfile(){
        this.setState({
            openProfileSection: !this.state.openProfileSection
        })
    }

    minimizeChat(){
        this.setState({
            isMinimizeChat: !this.state.isMinimizeChat
        })
    }
    render() {
        const { userDetail } = this.props;
        return (
            <div className={'userChat-wrapper'+(this.state.isMinimizeChat?' user-chat-minimize':'')}>
                <div className={'userChat-content'+(this.state.openProfileSection?' user-overlay':'')}>
                    <ChatHeader 
                        userDetail = {userDetail}
                        viewProfile = {this.viewProfile}
                        openProfileSection = {this.state.openProfileSection}
                        isMinimizeChat = {this.state.isMinimizeChat}
                        minimizeChat = {this.minimizeChat}
                        toggleMessage={this.props.toggleMessage}
                    />
                    <ChatSection
                        userDetail = {userDetail}
                        openProfileSection = {this.state.openProfileSection}
                    />
                </div>
            </div>
        );
    }
}

export default UserChat;