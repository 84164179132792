import React, { Component } from "react";
import UserFormButtons from "../.././../containers/userForms/UserFormButtons";
import { Label } from "reactstrap";
// import ToastErrorMsg from '../../toastErrorMsg/ToastErrorMsg';

class YourSectorForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sectorSelected: this.props.sectors.map((eachSector) => `${eachSector.id}`),
        };
    }

    componentDidMount = () => {
        this.props.getSectorList();
    };

    static getDerivedStateFromProps(props, state) {
        const { currentFormTab, sectors } = props;
        if (currentFormTab === "2") {
            if (sectors && sectors.length > 0 && sectors.length < 4) {
                props.setFieldsMissing(undefined);
            } else if (sectors && sectors.length === 0) {
                props.setFieldsMissing("Sector(s) - Three sectors maximum");
            }
        }
        return {};
    }

    handleAdditionalDetails = (event) => {
        this.props.setAdditionalDetails(event.target.value);
    };

    handleSectors = (sectorIndex) => {
        const { sectorSelected } = this.state;
        const isPresent = sectorSelected.includes(sectorIndex) ? 1 : 0;

        if (this.props.sectorLimit && sectorSelected.length === 3 && !isPresent) {
            return;
        }

        this.setState((prevState, props) => {
            const temp = prevState.sectorSelected;

            let addOneSector = [...prevState.sectorSelected, sectorIndex];

            let remOneSector = temp.filter((value, index, arr) => {
                return value !== sectorIndex;
            });

            let currentSelectedSectors = [];
            if (isPresent) {
                currentSelectedSectors = remOneSector;
            } else {
                currentSelectedSectors = addOneSector;
            }

            if (this.props.isNewPostModal) {
                this.props.setSectorsToNewPostModal(currentSelectedSectors, this.props.sectorList);
            }

            return {
                sectorSelected: currentSelectedSectors,
            };
        });
    };

    render() {
        const { sectorSelected } = this.state;
        // const { sectorList, fieldsMissing } = this.props;
        const { sectorList } = this.props;
        this.props.setSectors(this.state.sectorSelected);
        const userType = localStorage.getItem("user_type");
        return (
            <div className={`form-wrapper form-wrapper-user-info ${this.props.isNewPostModal ? "in-post-modal" : ""}`}>
                {/* <ToastErrorMsg fieldsMissing={fieldsMissing} customClass="userinfo-toast"/> */}
                {!this.props.isNewPostModal ? (
                    <div className="studentForm-head-block">
                        {userType === "alumni" ? (
                            <h6>Sector(s) relevant to my venture</h6>
                        ) : (
                            <h6>Sector(s) of interest</h6>
                        )}
                        <span>
                            Select up to three sectors
                            <br />
                            (These choices will help us shape your profile but will not restrict the types of ventures
                            you can explore)
                        </span>
                    </div>
                ) : null}
                <div className="form-container student-venture-form">
                    {sectorList &&
                        sectorList.map((sector, i) => (
                            <div
                                key={i}
                                onClick={() => this.handleSectors(sector.id)}
                                className={`venture-block ${
                                    sectorSelected.includes(sector.id) ? "venture-selected" : ""
                                }`}
                            >
                                <div className="venture-icon">
                                    <img src={sector.attributes["image-url"]} alt="no_image" />
                                </div>
                                <span>{sector.attributes.name}</span>
                            </div>
                        ))}
                    {!this.props.isNewPostModal && (userType === "student" || userType === "advisor") ? (
                        <div className="additional-block">
                            <Label>Additional Details (Optional) - Maximum 90 Characters</Label>
                            <textarea maxLength="90" onChange={this.handleAdditionalDetails} />
                        </div>
                    ) : (
                        ""
                    )}
                    {!this.props.isNewPostModal && (
                        <UserFormButtons
                            history={this.props.history}
                            setCurrentFormTab={this.props.setCurrentFormTab}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default YourSectorForm;
