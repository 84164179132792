import { connect } from 'react-redux';
import { getTimelineFeedList, getTimelineFeedListWithFilter } from '../api/filterUsers/getTimelineFeedList';
import { getFavorite } from '../api/favorites'
import FilterUsers from '../components/filterUsers/FilterUsers'
import { createNamespacer } from '../utils/reducers';
import { HOMEPAGE } from '../constants/namespacer'

const filterUsersNamespacer = createNamespacer(HOMEPAGE);
const favUserNamespacer = createNamespacer(HOMEPAGE);

const mapStateToProps = (state) => {
    return {
        timelineFeedFilteredList: state.filterUsers.timelineFeedFilteredList,
        timelineFeedFilteredListLength: state.filterUsers.timelineFeedFilteredListLength,
        timelineFeedFilteredListCurrentPage: state.filterUsers.timelineFeedFilteredListCurrentPage,
        timelineFeedFilteredListTotalPages: state.filterUsers.timelineFeedFilteredListTotalPages,

        userData: state.redirectAfterLogin.userData,
        selectedFilterList: state.filters.selectedFilterList,
        selectAll: state.filters.selectAll,
        favoriteUsersList: state.favourites.favoriteUsersList,
        favRefreshRequired: state.filterUsers.favRefreshRequired,
        favoriteUsersListLength: state.favourites.favoriteUsersListLength,
    }
};

const mapDispatchToProps = (dispatch) => {
    return{
        setfavRefreshRequired: () => {
            dispatch({
                type: favUserNamespacer('SET_FAV_REFRESH_REQ'),
                favRefreshRequired: false
            })
        },
        getTimelineFeedListWithFilter: async(params, selectedFilterListArr) => {
            let paramsString = '';
            const keys = Object.keys(params);
            keys.forEach((key, i) => {
                paramsString += key + '=' + params[key] + '&';
            });
            selectedFilterListArr.map(selectedFilterID => 
                paramsString = paramsString + 'interest_ids[]=' + selectedFilterID + '&'
            );
            paramsString = paramsString.substr(0, paramsString.length - 1);
            try {
                let response = await getTimelineFeedListWithFilter(paramsString);
                if (response.status === 200) {
                    dispatch({
                        type: filterUsersNamespacer('SET_TIMELINE_FEED_FILTERED_LEN'),
                        timelineFeedFilteredListLength: response.data.meta['total-records']
                    });
                    dispatch({
                        type: filterUsersNamespacer('SET_ALUMNI_FEED_FILTERED_LIST'),
                        timelineFeedFilteredList: response.data.data
                    });
                    dispatch({
                        type: filterUsersNamespacer('SET_TIMELINE_FEED_FILTERED_CURRENT_PAGE'),
                        timelineFeedFilteredListCurrentPage: response.data.meta['current-page']
                    });
                    dispatch({
                        type: filterUsersNamespacer('SET_TIMELINE_FEED_FILTERED_TOTAL_PAGES'),
                        timelineFeedFilteredListTotalPages: response.data.meta['total-pages']
                    });
                }
            }
            catch(error) {
                console.error(error)
            }
        },
        clearTimelineFeedFilteredList: () => {
            dispatch({
                type: filterUsersNamespacer('SET_TIMELINE_FEED_FILTERED_LEN'),
                timelineFeedFilteredListLength: 0
            });
            dispatch({
                type: filterUsersNamespacer('SET_ALUMNI_FEED_FILTERED_LIST'),
                timelineFeedFilteredList: []
            });
            dispatch({
                type: filterUsersNamespacer('SET_TIMELINE_FEED_FILTERED_CURRENT_PAGE'),
                timelineFeedFilteredListCurrentPage: 1
            });
            dispatch({
                type: filterUsersNamespacer('SET_TIMELINE_FEED_FILTERED_TOTAL_PAGES'),
                timelineFeedFilteredListTotalPages: 0
            });
        },
        getFavoriteList: async () => {
            try {
                let response = await getFavorite();
                let favoriteUsersList = response.data.data.map((favoriteUser) => {
                    return{
                        userId: favoriteUser.relationships.favourite.data.id,
                        favouriteId: favoriteUser.id,
                        notes: favoriteUser.attributes.notes,
                    }
                })
                dispatch({
                    type: favUserNamespacer('SET_FAVORITE_USER_LIST'),
                    favoriteUsersList: favoriteUsersList,
                })
            }
            catch (error) {
                console.log(error)
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterUsers);

