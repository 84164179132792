import React, { Component } from 'react'
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import LandingPage from '../../containers/landingPage'
import Usertype from "../../containers/UserType";
import UserFormMain from "../userForms/UserFormMain";
import RedirectAfterLogin from '../../containers/RedirectAfterLogin'
import ThankYou from '../../containers/Thankyou'
import HomePage from "../../containers/homePage/HomePage";
import UserProfile from '../../containers/UserProfile';
import EditProfile from '../../containers/editProfile/EditProfileMain';
import Profile from '../../containers/Profile';
import Match from '../../containers/match/Match'
import About from '../aboutUs/About';
import FAQPage from '../faqPage/FAQPage';
import NoUsers from '../noUsers/NoUsers';
import Messages from '../../containers/messages/Messages';
import MoreMenu from '../homePage/moreMenu/MoreMenu'
import Favourites from '../../containers/Favourites'
import AdminLogin from '../../containers/admin/AdminLogin';
import AdminHomePage from '../../containers/admin/AdminHomePage';
import AdminMoreUserDetail from '../../containers/admin/AdminMoreUserDetail';
import AdminExistingUsers from '../../containers/admin/AdminExistingUsers';
import AdminExistingUserMessages from '../../containers/admin/AdminExistingUserMessages'
import chatClient  from '../../services/twilio';
import { connect } from 'react-redux';
import { getTwilioTokenApi } from '../../api/twilioChat';
import { TWILIOCHAT } from '../../constants/namespacer'
import { createNamespacer } from '../../utils/reducers'
import AdminNotifications from '../../containers/admin/AdminNotifications';
import { UserSignUp } from '../../containers/userForms/UserSignUp'
import store from '../../store';
import { LOGIN } from '../../constants/namespacer';
import NewsFeed from '../../containers/NewsFeed';


const componentWithoutApi = ['/thankYou']

class Routes extends Component {
    constructor(props){
        super(props)
        this.state = {
            isMounted: false
        }
        this.toggleMount = this.toggleMount.bind(this)
    }

    toggleMount(){
        this.setState({ isMounted: true })
    }

    componentDidMount(){
        this.toggleMount()
    }

    componentDidUpdate(prevProps) {
        const currentPath = this.props.location.pathname
        if (currentPath !== prevProps.location.pathname
            && currentPath.includes(componentWithoutApi)) {
            this.props.getUserData()
        }
    }

    render() {
        const { isTokenValid, isAdmin } = this.props;
        return this.state.isMounted ? (
            <Switch>
                {
                    !isTokenValid ? commonRoutes(this.props) : (
                        isAdmin ? (adminRoutes(this.props)) : (
                            <PrivateRoutes />
                        )
                    )
                }
            </Switch>
        ) : null
    }
}

function commonRoutes(props) {
    return <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/admin/login" component={AdminLogin} />
        <Route path="/linkedin/auth/" component={RedirectAfterLogin} />
        <Route path="/userSignUp" component={UserSignUp} />
        <Route render={props => {
            return <Redirect push to="/" />
        }} />
    </Switch>
}

class PrivateRoutesComponent extends React.Component{
    state = {
        tcClient: null
    }

    componentDidMount = async () => {
        const token =  await this.props.getToken();
        const tcClient = await chatClient.initialize(token);
        this.setState({tcClient});
    }

    render(){
        return <>
            <Route path="/userType" component={Usertype} />
            <Route path="/userInfo" component={UserFormMain} />
            <Route path="/linkedin/auth" component={RedirectAfterLogin} />
            <Route path="/thankYou" component={ThankYou} />
            <Route path="/homepage"
                    render = { (routeProps) => (
                        <HomePage {...routeProps} tcClient={this.state.tcClient} />
                    )}
            />
            <Route path="/profile/:userId" component={Profile} />
            <Route path="/userProfile" component={UserProfile} />
            <Route path="/editProfile" component={EditProfile} />
            <Route path="/userMessage" component={Messages} />
            <Route path="/noUsers" component={NoUsers} />
            <Route path="/about" component={About} />
            <Route path="/faq" component={FAQPage} />
            <Route path="/moreMenu" component={MoreMenu} />
            <Route path="/favorites" component={Favourites} />
            <Route path="/match" component={Match} />
            <Route path="/newsFeed"
                render = { (routeProps) => (
                    <NewsFeed {...routeProps} tcClient={this.state.tcClient} />
                )}
            />
            <Route render={props => {
                return <Redirect push to="/linkedin/auth" />
            }} />
        </>
    }
}

const twilioChatNamespacer = createNamespacer(TWILIOCHAT);

const PrivateRoutes = connect(null, (dispatch)=>{
    return{
        getToken: async (params) => {
            try{
                let response = await getTwilioTokenApi();
                dispatch({
                    type: twilioChatNamespacer('SET_MSG_FROM_IDENTITY'),
                    msgFromIdentity: localStorage.getItem('id')
                })
                dispatch({
                    type: twilioChatNamespacer('SET_TWILIO_TOKEN'),
                    twilioToken: response.data
                })
                dispatch({
                    type: twilioChatNamespacer('SET_MSG_FROM_AUTHOR'),
                    msgFromAuthor: response.data.identity,
                })
                return response.data.token;
            }
            catch(error){
				if(error.response.status===500){
					const loginNamespacer = createNamespacer(LOGIN);
					localStorage.clear();
					store.dispatch({
						type: loginNamespacer('SET_IS_AUTHENTICATED'),
						isTokenValid: false
					})
				}
            }
        }
    }
})(PrivateRoutesComponent);

function adminRoutes(props) {
    return <Switch>
        <Route path="/admin/pendingAlumni" component={AdminHomePage} />
        <Route path="/admin/pendingStudents" component={AdminHomePage} />
        <Route path="/admin/pendingAdvisors" component={AdminHomePage} />
        <Route path="/admin/userMessages" component={AdminExistingUserMessages} />
        <Route path="/admin/existingUsers" component={AdminExistingUsers} />
        <Route path="/admin/userdetail/:pendingUserId" component={AdminMoreUserDetail} />
        <Route path="/admin/notifications" component={AdminNotifications} />
        <Route render={props => {
            return <Redirect push to="/admin/pendingAlumni" />
        }} />
    </Switch>
}

export default withRouter(Routes)