import React, { Component } from "react";
import { Link } from "react-router-dom";
import DesktopHeader from "../../containers/homePage/DesktopHeader";
import MobileMenu from "../../containers/homePage/MobileMenu";
import BackIcon from "../../commons/images/back-icon.svg";

class FAQPage extends Component {
    state = {};
    render() {
        return (
            <React.Fragment>
                <DesktopHeader />
                <div className="faq-wrapper screen-height">
                    <div className="edit-details-container about-us-subheader">
                        <span>Who we are</span>
                    </div>
                    <div className="custom-profile-container faq-container">
                        <div className="faq-content">
                            <h4 className="faq-subtitle">Who runs GradShack?</h4>
                            <p className="faq-desc">
                                GradShack was created and is managed by the principals of Collaborative Capital, LLC, a
                                New York based investment firm. The activities of GradShack are separate from the
                                activities of Collaborative Capital, though Collaborative Capital is not precluded from
                                investing in ventures arising from the GradShack platform.
                            </p>
                        </div>
                        <div className="faq-content">
                            <h4 className="faq-subtitle">Is GradShack officially endorsed by the schools?</h4>
                            <p className="faq-desc">
                                No, we have not sought any official partnerships with schools, though we enjoy
                                collaborating with them. GradShack is an independent platform.
                            </p>
                        </div>
                        <div className="faq-content">
                            <h4 className="faq-subtitle">Are there any costs I should be aware of?</h4>
                            <p className="faq-desc">No, GradShack does not charge fees or request equity ownership.</p>
                        </div>
                        <div className="faq-content">
                            <h4 className="faq-subtitle">So, what’s in it for GradShack?</h4>
                            <p className="faq-desc">
                                We are passionate about unearthing entrepreneurial talent, fostering intergenerational
                                collaboration, enhancing alumni engagement and supporting transformational ventures.
                            </p>
                        </div>
                    </div>
                </div>
                <MobileMenu />
            </React.Fragment>
        );
    }
}

export default FAQPage;
