import React, { Component } from "react";
import ProfilePage from "../profilePage/ProfilePage";

class UserProfile extends Component {
    componentDidMount() {
        this.props.getUserData();
    }

    componentWillReceiveProps(nextProps){
        const {
            name,
            lastName,
            email,
            linkedinProfileUrl,
            mbaProgram,
            colOrUniv,
            graduationYear,
            currentWorkLocation,
            concept,
            venture,
            additionalDetails,
            userId
        } = this.props;

        if (
            name !== nextProps.name ||
            lastName !== nextProps.lastName ||
            email !== nextProps.email ||
            linkedinProfileUrl !== nextProps.linkedinProfileUrl ||
            mbaProgram !== nextProps.mbaProgram ||
            colOrUniv !== nextProps.colOrUniv ||
            graduationYear !== nextProps.graduationYear ||
            currentWorkLocation !== nextProps.currentWorkLocation ||
            concept !== nextProps.concept ||
            venture !== nextProps.venture ||
            additionalDetails !== nextProps.additionalDetails ||
            userId !== nextProps.userId
        ) {
            this.props.getUserData();
        }
    }

    render() {
        const {
            userType,
            profilePicUrl,
            name,
            lastName,
            email,
            linkedinProfileUrl,
            mbaProgram,
            colOrUniv,
            graduationYear,
            currentWorkLocation,
            concept,
            venture,
            needs,
            sectors,
            additionalDetails,
            profilePicBase64,
            userId,
        } = this.props;

        return (
            <ProfilePage
                userId={userId}
                userType={userType}
                profilePicUrl={profilePicUrl}
                name={name}
                lastName={lastName}
                email={email}
                linkedinProfileUrl={linkedinProfileUrl}
                mbaProgram={mbaProgram}
                colOrUniv={colOrUniv}
                graduationYear={graduationYear}
                currentWorkLocation={currentWorkLocation}
                concept={concept}
                venture={venture}
                needs={needs}
                sectors={sectors}
                additionalDetails={additionalDetails}
                profilePicBase64={profilePicBase64}
            />
        );
    }
}

export default UserProfile;