import React, { Component } from "react";
import { Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import UserCard from '../../userCard/UserCard';
import FavouriteModal from '../../../containers/FavouriteModal';
import favourite from '../../../commons/images/favourite_icon.svg';
import favourite_select from '../../../commons/images/favourite-select.svg';

class FavouriteTimelineItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            favouriteModal: false,
            favoriteUserId: '',
            favoriteUserName: '',
            favUnfavModalLabel: '',
            favoriteUsersIdList: '',
            isFavorite: '',
        };

        this.favouriteModalToggle = this.favouriteModalToggle.bind(this);
    }

    componentDidMount() {
        this.props.getFavouriteTimelineItemUser(this.props.userId);
        this.props.getFavoriteList();
    }

    favouriteModalToggle(favoriteUserId, favoriteUserName, favUnfavModalLabel, isFavorite){
        this.setState({
            favoriteUserId,
            favoriteUserName,
            favUnfavModalLabel,
            isFavorite,
            favouriteModal: !this.state.favouriteModal
        })
    }

    render() {
        const { users, favoriteUsersIdList, favoriteUsersList, userId } = this.props;
        const { isFavorite, favoriteUserId, favoriteUserName, favUnfavModalLabel, favouriteModal } = this.state;
        const user = users[userId];
        const noteList = user && favoriteUsersList ? favoriteUsersList.filter(favUser => (favUser.userId === user.id)) : null;
        let noteText = '';
        if (noteList && noteList[0]) {
            noteText = noteList.notes
        }

        return(
            <>
                {
                    user && user.attributes &&
                    <>
                        <div className="filter-user-container selected-filter-user">
                            <div className="filter-user-basic-deatils">
                                <UserCard
                                    userType={user.attributes["user-type"]}
                                    userDetail={user}
                                />
                                <div
                                    className="favourite-profile"
                                    data-tip data-for='favourites'
                                    onClick={() => {this.favouriteModalToggle(
                                        user.id,
                                        `${user.attributes['first-name']} ${user.attributes['last-name']}`,
                                        'You are unfavoriting'
                                    )}}
                                >
                                    <img src={favoriteUsersIdList.includes(user.id) ? favourite_select : favourite} alt="no_image"/>
                                </div>
                                <ReactTooltip id='favourites' type="warning" effect="solid" data-tip-disable="true">
                                    <span>Remove from Favorites</span>
                                </ReactTooltip>
                            </div>
                            <div className="add-notes">
                                <div className="notes-area">
                                    <div className="notes-label">
                                        Note - {noteText}
                                    </div>
                                </div>
                            </div>
                            <Button
                                className="message-btn"
                                onClick={()=> {this.props.toggleUserMessage(user)}}
                            >Send a Message</Button>
                        </div>
                        <FavouriteModal
                            isFavorite={isFavorite}
                            favoriteUserId={favoriteUserId}
                            favoriteUserName={favoriteUserName}
                            favUnfavModalLabel={favUnfavModalLabel}
                            isOpen={favouriteModal}
                            toggle={this.favouriteModalToggle}
                            favouriteModalToggle={this.favouriteModalToggle}
                            favoriteUsersList={favoriteUsersList}
                            getFavoriteList={this.props.getFavoriteList}
                        />
                    </>
                }
            </>

        )
    }
}

export default FavouriteTimelineItem;
