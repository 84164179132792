import { connect } from 'react-redux';
import { getUserData } from '../api/commonApi/getUserData'
import UserProfile from '../components/userProfile/UserProfile'
import { createNamespacer } from '../utils/reducers';
import { USERFORMDATA, USERTYPE } from '../constants/namespacer'

const userFormDataNamespacer = createNamespacer(USERFORMDATA);
const userTypeNamespacer = createNamespacer(USERTYPE);

const mapStateToProps = (state) => {
    return {
        userType: state.userType.userType,
        profilePicUrl: state.yourInfoForm.profilePicUrl,
        userId: state.yourInfoForm.userId,
        name: state.yourInfoForm.name,
        lastName: state.yourInfoForm.lastName,
        email: state.yourInfoForm.email,
        linkedinProfileUrl: state.yourInfoForm.linkedinProfileUrl,
        mbaProgram: state.yourInfoForm.mbaProgram,
        colOrUniv: state.yourInfoForm.colOrUniv,
        graduationYear: state.yourInfoForm.graduationYear,
        currentWorkLocation: state.yourInfoForm.currentWorkLocation,
        concept: state.yourVentureForm.concept,
        venture: state.yourVentureForm.venture,
        needs: state.yourVentureForm.needs,
        sectors: state.yourSectorForm.sectors,
        additionalDetails: state.yourSectorForm.additionalDetails,
        isTokenValid: state.redirectAfterLogin.isTokenValid,
        profilePicBase64: state.yourInfoForm.profilePicBase64,
        profileLoading: state.yourInfoForm.profileLoading,

    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        getUserData: async() => {
            try {
                dispatch({
                    type: userFormDataNamespacer('SET_PROFILE_LOADER_VALUE'),
                    profileLoading: true
                })
                let response  = await getUserData();
                if (response.status === 200) {

                    const { attributes, id } = response.data.data;

                    const userType = attributes['user-type'];
                    const profilePic = attributes['image-url'];
                    const firstName = attributes['first-name'];
                    const lastName = attributes['last-name'];
                    const email = attributes.email;
                    const linkedinProfileUrl = attributes['profile-url'];
                    const mbaProgram = attributes['mba-program'];
                    let colOrUniv;
                    if(attributes.university){
                        colOrUniv = attributes.university.nickname || attributes.university.name;
                    }
                    const graduationYear = attributes['grad-year'];
                    let currentWorkLocation;
                    if(attributes.location){
                        currentWorkLocation = `${attributes.location.name}, ${attributes.location.code}`;
                    }
                    let concept, venture, needs, additionalDetails
                    if(userType==='alumni'){
                        concept = attributes['alumni-detail'].concept;
                        venture = attributes['alumni-detail'].state;
                        needs = attributes['alumni-detail']['need-today'];
                    }
                    else if(userType==='student' || userType==='advisor'){
                        additionalDetails = attributes['interest-desc'];
                    }
                    const sectors = attributes.interests;

                    dispatch({
                        type: userTypeNamespacer('SET_USERTYPE_VALUE'),
                        userType: userType
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_PROFILE_PIC_URL'),
                        profilePicUrl: profilePic
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_PROFILE_PIC_BASE64'),
                        profilePicBase64: profilePic
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_PROFILE_PIC_BASE64_COPY'),
                        profilePicBase64Copy: profilePic
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_NAME_VALUE'),
                        name: firstName
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_LAST_NAME_VALUE'),
                        lastName: lastName
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_EMAIL_VALUE'),
                        email: email
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_LINKEDIN_PROFILE_URL_VALUE'),
                        linkedinProfileUrl: linkedinProfileUrl,
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_MBA_PROGRAM_VALUE'),
                        mbaProgram: mbaProgram
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_COL_OR_UNIV_VALUE'),
                        colOrUniv: colOrUniv
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_GRADUATION_YEAR_VALUE'),
                        graduationYear: graduationYear
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_CURRENT_WORK_LOCATION_VALUE'),
                        currentWorkLocation: currentWorkLocation
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_CONCEPT_VALUE'),
                        concept: concept
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_VENTURE_VALUE'),
                        venture: venture
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_NEEDS_VALUE'),
                        needs: needs
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_SECTORS_VALUE'),
                        sectors: sectors
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_ADDITIONAL_DETAILS'),
                        additionalDetails: additionalDetails
                    })

                    dispatch({
                        type: userFormDataNamespacer('SET_USER_ID'),
                        userId: id
                    })
                }
            }
            catch(error){
                console.log(error)
            }
            finally{
                dispatch({
                    type: userFormDataNamespacer('SET_PROFILE_LOADER_VALUE'),
                    profileLoading: false
                })
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
