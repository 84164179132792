import React, { Component, Fragment } from "react";
import moment from "moment";
import ChatViewProfile from '../userChat/ChatViewProfile';

const getTimeOfMsg = timestamp => {
    return moment(timestamp).format("LT");
};

const getDateOfMsg = (timestamp) => {
    return moment(timestamp).format('ddd, MMM Do');
}

class AdminExistingUserMessagesBlockChat extends Component {
    constructor(props) {
        super(props);
        this.messagesEndRef = React.createRef();
    }

    componentDidMount() {
        this.scrollToBottom();
    }
    componentDidUpdate() {
        this.scrollToBottom();
    }
    scrollToBottom = () => {
        this.messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    };

    render() {
        const {
            openProfileSection,
            messages,
            tcCurrentChannel,
            userEmail,
            secondUserData,
        } = this.props;

        let oldDay;
        let latestDay;

        return (
            <>
                <div className="chat-section-container direct-message-wrapper">
                    <ChatViewProfile
                        userDetail={tcCurrentChannel.secondUserDetail}
                        openProfileSection={openProfileSection}
                    />
                    <div className="chat-section-wrapper message-chat-wrapper">
                        <div className="chat-inner-container">
                            {messages.length > 0
                                ? messages.map((msg, index) => {
                                    oldDay=latestDay;
                                    latestDay = getDateOfMsg(msg.timestamp.toString());
                                    
                                    let dayToDisplay;
                                    dayToDisplay = oldDay !== latestDay ? latestDay : oldDay;

                                    return <Fragment key={index}>
                                    {   oldDay !== latestDay ? 
                                        <p className='date-in-msg-box'>
                                            <span>{dayToDisplay}</span>
                                        </p> : ''
                                    }

                                    {
                                        secondUserData ? 
                                        <div
                                            className={`chat-user 
                                            ${
                                                msg.author === secondUserData.attributes.email ? "chat-user1"
                                                : msg.author === userEmail ? "chat-user2" : ""
                                            }`}
                                        >
                                            {
                                                msg.author === secondUserData.attributes.email ? 
                                                    <>
                                                        <span className="chat-text">{msg.body}</span>
                                                        <span className="chat-time">{getTimeOfMsg(msg.timestamp.toString())}</span>
                                                    </>
                                                : msg.author === userEmail ? 
                                                    <>
                                                        <span className="chat-time">{getTimeOfMsg(msg.timestamp.toString())}</span>
                                                        <span className="chat-text">{msg.body}</span>
                                                    </>
                                                : ''
                                            }
                                        </div> : ''
                                    }
                                    </Fragment>
                                }) : ''
                            }
                        </div>
                        <div ref={this.messagesEndRef} />
                    </div>
                </div>
            </>
        );
    }
}

export default AdminExistingUserMessagesBlockChat;
