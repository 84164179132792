import React, {Component} from 'react';
import { Table, Button } from 'reactstrap';
import LeftArrow from '../../commons/images/leftArrow.svg';
import DesktopHeader from '../../containers/homePage/DesktopHeader';
import NoUsers from '../noUsers/NoUsers'
import ApproveModal from '../../containers/admin/ApproveModal'
import { Redirect } from 'react-router-dom'
import Pagination from '../shared/Pagination';

class AdminHomePage extends Component {

    constructor(props){
        super(props);
        this.state = {
            modal: false,
            approvalModal: false,
            messageBlock: false,
            userType: '',
            pendingUserId: '',
            pendingUserName: '',
            approveRejectLabel: '',
            isReject: '',
            pendingUserList: [],
            redirectTo: null,
            pendingUserType: '',
            currentPage: 1,
        }
        this.toggle = this.toggle.bind(this);
        this.approveModalToggle = this.approveModalToggle.bind(this);
        this.getPendingUser = this.getPendingUser.bind(this);
    }

     toggle() {
        this.setState({
            modal: !this.state.modal
        })
    }

    approveModalToggle(pendingUserId, pendingUserName, approveRejectLabel, pendingUserType, isReject){
        this.setState({
            pendingUserId,
            pendingUserName,
            approveRejectLabel,
            isReject,
            pendingUserType,
            approvalModal: !this.state.approvalModal,
        })
    }

    getPendingUser(page=1) {
        const pendingUserType = this.props.location.pathname.split('/')[2];
        if(pendingUserType==='pendingAlumni'){
            this.props.getPendingUserList('pending', 'alumni', page);
        }
        else if(pendingUserType==='pendingStudents'){
            this.props.getPendingUserList('pending', 'student', page);
        }
        else if(pendingUserType==='pendingAdvisors'){
            this.props.getPendingUserList('pending', 'advisor', page);
        }
    }

    componentDidMount(){
        this.setState({currentPage: 1});
        this.getPendingUser();
    }
    
    setCurrentPage = (page) => {
        this.setState({currentPage: page});
    }

    handleCurrentPage = (page) => {
        console.log(page)
        this.setCurrentPage(page);
        this.getPendingUser(page);
    }

    handleMoreUserDetail = (pendingUserId) => {
        this.setState({redirectTo: `/admin/userdetail/${pendingUserId}`});
    }

    render() {
        const { pendingUserList }= this.props;
        const { pendingUserId, pendingUserName, approveRejectLabel, isReject, redirectTo, pendingUserType }  = this.state;

        let pendingUserListArr = pendingUserList.length>0 ?
                                pendingUserList.map((pendingUser, index) => (
                                    <tr key={index}>
                                        <th scope="row">{(this.state.currentPage-1)*20 + index+1}</th>
                                        <td>{`${pendingUser.attributes['first-name']} ${pendingUser.attributes['last-name']}`}</td>
                                        <td>{pendingUser.attributes['requested-date']}</td>
                                        <td>
                                            <Button
                                                onClick={() => {this.approveModalToggle(pendingUser.id, `${pendingUser.attributes['first-name']} ${pendingUser.attributes['last-name']}`, 'You are Approving', pendingUser.attributes['user-type'])}}
                                            >Approve</Button>
                                            <Button
                                                onClick={() => {this.approveModalToggle(pendingUser.id, `${pendingUser.attributes['first-name']} ${pendingUser.attributes['last-name']}`, 'You are Rejecting', pendingUser.attributes['user-type'], true)}}
                                            >Reject</Button>
                                        </td>
                                        <td>
                                            <div onClick={() => {this.handleMoreUserDetail(pendingUser.id)}}>
                                                <img src={LeftArrow} alt="no_image"/>
                                            </div>
                                        </td>
                                    </tr>
                                )) : null;

        return (
            <React.Fragment>
                <DesktopHeader
                    currentPage={this.state.currentPage}
                    setCurrentPage={this.setCurrentPage}
                />
                {
                    pendingUserListArr && pendingUserListArr.length>0 ?
                    <Table className="custom-container-wrapper admin-users-table">
                        <thead>
                            <tr>
                                <th>S.no.</th>
                                <th>Name</th>
                                <th>Requested Date & Time</th>
                                <th>Action</th>
                                <th>More details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pendingUserListArr}
                        </tbody>
                    </Table> : <NoUsers for='pending' className="admin-screen-height-no-users" />
                }
                {
                    this.props.totalRecords>this.props.recordsPerPage &&
                    <Pagination
                        current={this.state.currentPage}
                        total={this.props.totalRecords}
                        pageSize={this.props.recordsPerPage}
                        onChange={this.handleCurrentPage}
                    />
                }
                <ApproveModal
                    isReject={isReject}
                    pendingUserId={pendingUserId}
                    pendingUserName={pendingUserName}
                    approveRejectLabel={approveRejectLabel}
                    isOpen={this.state.approvalModal}
                    toggle={this.approveModalToggle}
                    approveModalToggle={this.approveModalToggle}
                    history={this.props.history}
                    pendingUserType={pendingUserType}
                />
                {redirectTo ? <Redirect to={redirectTo} /> : null}
            </React.Fragment>
        );
    }
}

export default AdminHomePage;