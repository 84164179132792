import { connect } from 'react-redux';
import { createNamespacer } from '../../utils/reducers';
import { TWILIOCHAT } from '../../constants/namespacer';
import { getTwilioTokenForUserApi } from '../../api/adminHomePage'
import { getSecondUserDetailApi } from '../../api/twilioChat'
import AdminExistingUserMessages from '../../components/adminExistingUsers/AdminExistingUserMessages'
import store from '../../store'
import chatClient from '../../services/twilio'
import { getMessageCount } from '../../constants/fixedValues';

const twilioChatNamespacer = createNamespacer(TWILIOCHAT);

const mapStateToProps = (state) => {
    return {
        existingUserType: state.adminExistingUsers.existingUserType,
        twilioToken: state.twilioChat.twilioToken,
        msgFromIdentity: state.twilioChat.msgFromIdentity,
        subscribedChannels: state.twilioChat.subscribedChannels,
        activeUserChatIndex: state.twilioChat.activeUserChatIndex,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setActiveUserChatIndex: (value) => {
            dispatch({
                type: twilioChatNamespacer('SET_ACTIVE_USER_CHAT_INDEX'),
                activeUserChatIndex: value,
            })
        },
        getTwilioTokenForUser: async(userId, userEmail) => {
            try{
                const response = await getTwilioTokenForUserApi(userEmail);
                dispatch({
                    type: twilioChatNamespacer('SET_MSG_FROM_IDENTITY'),
                    msgFromIdentity: userId
                })
                dispatch({
                    type: twilioChatNamespacer('SET_TWILIO_TOKEN'),
                    twilioToken: response.data
                })
                chatClient.client = null;
            }
            catch(error){
                console.log(error)
            }
        },
        get createTwilioClient(){
            return async () => {
                try{
                    const twilioUserToken = store.getState().twilioChat.twilioToken;
                    await chatClient.initialize(twilioUserToken.token);
                    
                    const msgFromIdentity = store.getState().twilioChat.msgFromIdentity
                    return await this.getUserSubscribedChannels(msgFromIdentity); 
                }
                catch(error){
                    console.log(error)
                }
            }
        },
        get getUserSubscribedChannels(){
            return async (msgFromIdentity) => {
                try{
                    const subscribedChannels = await chatClient.client.getSubscribedChannels();

                    const channelWithOtherDetails = await this.getChannelWithOtherDetails(subscribedChannels.items, msgFromIdentity);
                    
                    const filterChannelWithOtherDetails = channelWithOtherDetails.filter(channel => {
                        return channel.secondUserDetail !== undefined && channel.latestMessage !== undefined;
                    })
                    
                    filterChannelWithOtherDetails.sort((a, b) => b.latestMessageTimeStamp - a.latestMessageTimeStamp);

                    dispatch({
                        type: twilioChatNamespacer('SET_SUBSCRIBED_CHANNEL'),
                        subscribedChannels: filterChannelWithOtherDetails,
                    })

                    return filterChannelWithOtherDetails;
                }
                catch(error){
                    console.log(error)
                }
            }
        },
        get getChannelWithOtherDetails(){
            return async (subscribedChannelsArr, msgFromIdentity) => {
                try{
                    return await Promise.all(subscribedChannelsArr.map(async (channel) => {
                        const secondUserId = channel.uniqueName.split('-').filter((userId) => (
                            userId != msgFromIdentity
                        ))

                        let secondUserDetail;
                        if (secondUserId.length === 1){
                            secondUserDetail = await this.getSecondUserDetail(secondUserId[0], false);
                        }
                        const messages = await channel.getMessages(getMessageCount);
                        const totalMessagesCountOnChannel = messages.items.length;

                        let latestMessage;
                        let latestMessageTimeStamp;

                        if(totalMessagesCountOnChannel>0){
                            latestMessage = messages.items[totalMessagesCountOnChannel-1].body
                            latestMessageTimeStamp = messages.items[totalMessagesCountOnChannel-1].timestamp
                        }
                        return({channel, secondUserDetail, messages, latestMessage, latestMessageTimeStamp})
                    }))
                }
                catch(error){
                    console.log(error)
                }
            }
        },
        get getSecondUserDetail(){
            return async (secondUserId, setReduxState) => {
                try{
                    let response  = await getSecondUserDetailApi(secondUserId);
                    if(response.status===200){
                        if(setReduxState){
                            dispatch({
                                type: twilioChatNamespacer('SET_SECOND_USER_DATA'),
                                secondUserData: response.data.data,
                            })
                        }
                        else{
                            return response.data.data;
                        }
                    } 
                }
                catch(error){
                    console.log(error)
                }
            }
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminExistingUserMessages);

