import { connect } from 'react-redux';
import { createNamespacer } from '../../utils/reducers';
import { TWILIOCHAT } from '../../constants/namespacer';
import { addMemberToChannelApi } from '../../api/twilioChat';
import ChatSection from '../../components/userChat/chatSection/ChatSection';
import chatClient from '../../services/twilio';
import { getMessageCount } from '../../constants/fixedValues'

const twilioChatNamespacer = createNamespacer(TWILIOCHAT);

const mapStateToProps = (state) => {
    return {
        twilioToken: state.twilioChat.twilioToken,
        msgFromIdentity: state.twilioChat.msgFromIdentity,
        messages: state.twilioChat.messages,
        sendMessageText: state.twilioChat.sendMessageText,
        tcCurrentChannel: state.twilioChat.tcCurrentChannel,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        setMessages: () => {
            dispatch({
                type: twilioChatNamespacer('SET_MESSAGES'),
                messages: [],
            })
        },
        setSendMessageText: (value) => {
            dispatch({
                type: twilioChatNamespacer('SET_SEND_MSG_TEXT'),
                sendMessageText: value,
            })
        },
        get getChannelOrCreateChannel(){
            return async (fromIdentity, toIdentity, fromEmail, toEmail) => {
                const newChannelDetail = { 
                    isPrivate: true, 
                    uniqueName: fromIdentity<toIdentity ? `${fromIdentity}-${toIdentity}` : `${toIdentity}-${fromIdentity}`, 
                }

                let channel;
                try{
                    channel = await chatClient.client.getChannelByUniqueName(newChannelDetail.uniqueName)
                }
                catch(error){
                    console.log(error)
                    if(error.toString()==='Error: Not Found'){
                        channel = await this.createNewChannel(newChannelDetail, fromIdentity, toIdentity, fromEmail, toEmail)
                    }
                }

                chatClient.attachHandlersToChannel(channel);

                dispatch({
                    type: twilioChatNamespacer('SET_TC_CURRENT_CHANNEL'),
                    tcCurrentChannel: channel,
                })
                if(channel){
                    this.getMessageFromChannel(channel);
                }

            }
        },
        get addMemberToChannel(){
            return async (channel, fromIdentity, toIdentity, fromEmail, toEmail) => {
                let bodyFormData = new FormData();
                bodyFormData.append('channel_sid', channel.sid );
                bodyFormData.append('from', fromEmail );
                bodyFormData.append('to', toEmail );
                bodyFormData.append('sender_id', fromIdentity );
                bodyFormData.append('receiver_id', toIdentity );

                try{
                    await addMemberToChannelApi(bodyFormData);
                }
                catch(error){
                    console.log(error);
                }
            }
        },
        get createNewChannel(){
            return async (newChannelDetail, fromIdentity, toIdentity, fromAuthor, toAuthor) => {
                try{
                    const newChannel = await chatClient.client.createChannel(newChannelDetail);
                    if(newChannel){
                        this.addMemberToChannel(newChannel, 
                                                fromIdentity, 
                                                toIdentity, 
                                                fromAuthor,        
                                                toAuthor)
                        return newChannel
                    }
                    await newChannel.updateLastConsumedMessageIndex(0);
                }
                catch(error){
                    console.log(error);
                }
            }
        },
        get sendMessageToChannel(){
            return async (channel, msg) => {
                try{
                    await channel.sendMessage(msg);

                    const lastMessageIndex = channel.lastMessage.index;
                    await channel.updateLastConsumedMessageIndex(lastMessageIndex);
                }
                catch(error){
                    console.log(error)
                }
            }
        },
        get getMessageFromChannel(){
            return async (channel) => {
                try{
                    const messages = await channel.getMessages(getMessageCount);
                    dispatch({
                        type: twilioChatNamespacer('SET_MESSAGES'),
                        messages: messages.items
                    })
                }
                catch(error){
                    console.log(error)
                }
            }
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatSection);

