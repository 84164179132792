import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

export default class RedirectAfterLogin extends Component {
    state={
        userData: '',
        redirectTo: null,
    }

    componentWillMount(){
        const allParams = window.location.search.substr(1);
        if(allParams){
            const params = allParams.split('&');
            let authCode = params[0].split('=')[1]
            let iD = params[1].split('=')[1];
            localStorage.setItem('auth_code', authCode);
            localStorage.setItem('id', iD);
            localStorage.setItem('isAdmin', false);
            localStorage.setItem('isLogin', true)
            
            this.props.setAuthCode(authCode);
            this.props.setUserId(iD);
        }
    }

    componentDidMount(){
        this.props.getUserData();
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.userData !== this.props.userData){
            this.setState({userData: nextProps.userData});
        }
    }

    routeChange = (path) => {
        this.setState({redirectTo: path})
    }

    getLinkedinDetail = () => {
        const { userData } = this.state;
        
        if(userData){
            const { attributes } = this.props.userData;
            localStorage.setItem('user_type', attributes['user-type']);
            
            if (attributes.interests.length === 0 || attributes['user-type'] === null) {
                this.routeChange('/userInfo');
            }
			else if(attributes['user-type'] === 'alumni' && attributes['alumni-detail'] === null) {
                this.routeChange('/userInfo');
			}
            else if(attributes.status==='rejected'){
                this.routeChange('/thankYou');
            }
            else if(attributes.status==='approved' || attributes.status ==='pending'){
                this.routeChange('/newsFeed');
            }
        }
    }

    render() {
        const { userData, redirectTo } = this.state;
        if(userData){
            this.getLinkedinDetail();
        }
        return (
            <div>
                { redirectTo ? <Redirect to={redirectTo} /> : null }
            </div>
        )
    }
}
