import React, { Component } from 'react';
import {
    Form,
    FormGroup,
    CustomInput,
    Button,
    Label,
    Row,
    Col,
} from 'reactstrap'
import { Redirect } from 'react-router-dom'
import logo from '../../commons/images/logo.svg'
import PrivacyModal from '../staticPages/privacyPolicy'
import TermsModal from '../staticPages/terms';
import FormInput from '../commonComponents/FormGroupInputBox';
import Reaptcha from 'reaptcha';

export class UserSignUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isValidPassword: true,
            isConfirm: true,
            isValidEmail: true,
            isValidAllValue: false,
            privacyModal: false,
            termsModal: false,
            acceptedPrivacy: false,
            acceptedTerms: false,
			recaptchaVerified: false,
        }

        this.privacyModalToggle = this.privacyModalToggle.bind(this)
        this.termsModalToggle = this.termsModalToggle.bind(this)
    }

    privacyModalToggle() {
        this.setState({
            privacyModal: !this.state.privacyModal
        })
    }

    termsModalToggle() {
        this.setState({
            termsModal: !this.state.termsModal
        })
    }

    handlerUserPassword = (event) => {
        this.props.setUserPassword(event.target.value)
        if (!this.passwordValidate(event.target.value)) {
            this.setState({ isValidPassword: false })
        } else {
            this.setState({ isValidPassword: true })
        }
        if(this.props.confirmPassword !== ""){
            if(event.target.value !== this.props.confirmPassword) {
                this.setState({ isConfirm: false })
            }else{
                this.setState({ isConfirm: true })
            }
        }
    }

    handlerUserEmail = (event) => {
        this.props.setUserEmail(event.target.value)
        this.emailValidate(event.target.value)
    }

    emailValidate = (email) => {
        if (/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g.test(email)) {
            this.setState({ isValidEmail: true })
        } else {
            this.setState({ isValidEmail: false })
        }
    }
    handlerUserSignUp = () => {
        const { userEmail, userPassword, confirmPassword, captchaToken } = this.props
        const {
            isValidEmail,
            isConfirm,
        } = this.state;
        
        if (isValidEmail && isConfirm) {
            this.setState({ isValidAllValue: true });
			this.captcha.reset();
            if (window.hasOwnProperty('gtag')) {
                window.gtag('event', 'sign_up', {
                    method: 'Internal',
                    event_category: 'User',
                    event_label: 'Sign Up',
                    value: 'email: ' + userEmail,
                });
            }
            this.props.userSignUp(userEmail, userPassword, confirmPassword, captchaToken );
            return <Redirect to="/userInfo" />
        } else {
            alert('Enter Email and Password')
        }
    };

    passwordValidate(p) {
        return /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])[\w!@#$%^&*]{8,}$/.test(p)
    }

    handleConfirmPassword = (event) => {
        this.props.setUserConfirmPassword(event.target.value)
        if (event.target.value !== this.props.userPassword) {
            this.setState({ isConfirm: false })
        } else {
            this.setState({ isConfirm: true })
        }
    }

    handlerPrivacy = () => {
        this.setState(({ acceptedPrivacy }) => {
            const newacceptedPrivacy = !acceptedPrivacy
            return {
                acceptedPrivacy: newacceptedPrivacy
            }
        })
    }

    handlerTerms = () => {
        this.setState(({ acceptedTerms }) => {
            const newacceptedTerms = !acceptedTerms
            return {
                acceptedTerms: newacceptedTerms
            }
        })
    }

	onRecaptchaVerify= (response)=> {
		this.setState({
            recaptchaVerified: true
        })
        this.props.setUserCaptchaToken(response)
		console.log('verified',response)
	}

    render() {
        return (
            <React.Fragment>
                <div className="signup-wrapper d-flex flex-column flex-md-row">
                    <div className="signup-container d-flex">
                        <div className="signup-inner-container">
                            <img src={logo} alt="Gradshack" />
                            <h4 className="title">
							Startups, ideas and idea-seekers from your school.
                            </h4>
                            <Form className="form-container signup-form">
                                <FormInput 
                                    type = "email"
                                    placeholder = "Email"
                                    isValid = {this.state.isValidEmail}
                                    onChange={this.handlerUserEmail}
                                    invalidMessage = "Invalid email."
                                />

                                <FormInput 
                                    type = "password"
                                    placeholder = "Password"
                                    isValid = {this.state.isValidPassword}
                                    onChange = {this.handlerUserPassword}
                                    invalidMessage = " Password must be at least eight characters long and must contain at least one number, one upper case letter and one lower case letter."
                                />

                                <FormInput 
                                    type = "password"
                                    placeholder = "Confirm Password"
                                    isValid = {this.state.isConfirm}
                                    onChange = {this.handleConfirmPassword}
                                    invalidMessage = "Passwords do not match."
                                />
								<div className="recaptcha">
									<Reaptcha sitekey="6LdVFW8aAAAAAPkKfm0TRKRTD7sgGRG5W0K2c8Qe" onVerify={this.onRecaptchaVerify} ref={e => (this.captcha = e)} />
								</div>
                                
                                {/* <FormGroup>
                                    <Row>
                                        <Col xs="1">
											<CustomInput className="customCheckbox"
												id="checkboxTerms"
                                                type="checkbox"
                                                onChange={this.handlerTerms}
                                            />
                                        </Col>
                                        <Col>
                                            <Label
                                                className="customCheckbox"
                                                check
                                            >
                                                I agree to Gradshack&#39;s{' '}
                                                <span
                                                    className="privacy-link"
                                                    onClick={
                                                        this.termsModalToggle
                                                    }
                                                >
                                                    Terms of Use
                                                </span>
                                            </Label>
                                        </Col>
                                    </Row>
                                </FormGroup> */}
                                {/* <FormGroup>
                                    <Row>
                                        <Col xs="1">
                                            <CustomInput className="customCheckbox"
												id="checkboxPrivacy"
                                                type="checkbox"
                                                onChange={this.handlerPrivacy}
                                            />
                                        </Col>
                                        <Col>
                                            <Label
                                                className="customCheckbox"
                                                check
                                            >
                                                I agree to Gradshack&#39;s{' '}
                                                <span
                                                    className="privacy-link"
                                                    onClick={
                                                        this.privacyModalToggle
                                                    }
                                                >
                                                    Privacy Policy
                                                </span>
                                            </Label>
                                        </Col>
                                    </Row>
                                </FormGroup> */}

                                <Button
                                    className="buttonSignUp"
                                    onClick={this.handlerUserSignUp}
                                    disabled={
                                        !(
                                            this.state.isValidEmail &&
                                            this.state.isValidPassword &&
                                            this.state.isConfirm &&
											this.state.recaptchaVerified
                                        )
                                    }
                                >
                                    Register
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
                <PrivacyModal
                    isOpen={this.state.privacyModal}
                    toggle={this.privacyModalToggle}
                />
                <TermsModal
                    isOpen={this.state.termsModal}
                    toggle={this.termsModalToggle}
                />
            </React.Fragment>
        )
    }
}
