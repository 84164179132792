import React, { useState } from "react";
import upload from "../../../commons/images/upload-arrow.png";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';
import profilePic from "../../../commons/images/profile_pic.svg";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const UserFormModal = (props) => {

    const [image, setImage] = useState(props.profilePic);
    const [cropper, setCropper] = useState();
    const [fileSelected, setFileSelected] = useState(false);

    const selectPicture = (e) => {
        e.preventDefault();
        setFileSelected(true);
        let fileInput =  document.getElementById('file'); 
        let filePath = fileInput.value; 
        let allowedExtensions =  /(\.jpg|\.png|\.jpeg)$/i; 
        if (!allowedExtensions.exec(filePath)) { 
            alert('Invalid file type'); 
            fileInput.value = ''; 
            return false; 
        } 
        else{ 
            let files;
            if (e.dataTransfer) {
            files = e.dataTransfer.files;
            } else if (e.target) {
            files = e.target.files;
            }

            if(files[0].size/1024 < 4000){       //Checking if the size of image is less than 4MB
                const reader = new FileReader();
                reader.onload = () => {
                setImage(reader.result);
                };
                reader.readAsDataURL(files[0]);
            }
            else{
                alert('Image size exceeds 4MB'); 
            }
            
        }
    };

    const cropAndUpdateData = () => {
        if (image && typeof cropper !== "undefined") {
            const croppedCanvasMethod = cropper.getCroppedCanvas()
            if(croppedCanvasMethod && croppedCanvasMethod.toDataURL ){
                props.handleProfilePicChange(croppedCanvasMethod.toDataURL())
            }
            if(croppedCanvasMethod && croppedCanvasMethod.toBlob) { 
            croppedCanvasMethod.toBlob((blob) => {
                props.setProfilePic(blob);
            }, "image/jpeg" , 0.5)}       // Adding compression ratio
        }
        closeModal();
        setFileSelected(false);
    }

    const closeModal = () => {
        // setImage("");
        props.toggle();
    }

        return (
            <Modal isOpen={props.isOpen} toggle={closeModal} className="aboutUs-modal"> 
                <ModalHeader toggle={closeModal} className="modal-head">
                    {/* <img src={logo} alt="Gradshack" /> */}
                    Edit Profile Picture
                </ModalHeader>
                <ModalBody className="about-details">                    
                    <div className="photo-upload">
                       {!image ?
                            <img src={profilePic} alt="Profile pic" className="profile-img"/>
                        :
                        null
                        }
                        <Cropper
                            style={{height: image ? 250 : 60,width: "100%" }}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={image}
                            viewMode={1}
                            guides={true}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} 
                            cropBoxResizable={false}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            checkCrossOrigin={false}
                        />
                    </div>
                    <div className="align-center">
                        <label className="upload-button">
                            <input type="file" onChange={selectPicture} id='file'/>
                            <img src={upload} alt="Upload" className="upload-image"/>                           
                            Upload a new photo
                        </label>
                            <div className="info-div">JPG, PNG & JPEG files under 4 MB</div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary no-color" onClick={closeModal}>Cancel</Button>{' '}
                    <Button color="primary" disabled={!fileSelected} onClick={cropAndUpdateData}>Update</Button>
                </ModalFooter>
            </Modal>
        );
}
export default UserFormModal;